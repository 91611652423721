.main.inner {
  .breadcrumbs {
    margin-top: 50px;
    @include tablet {
      margin-top: 35px;
    }
    @include mobile {
      display: none;
      margin-top: 0;
    }
  }

  .news {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px 30px;
    margin-bottom: 75px;
    @include small-tablet {
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      margin-bottom: 60px;
    }
    @include mobile {
      grid-template-columns: 100%;
      gap: 30px 15px;
      margin-bottom: 50px;
    }

    &__title {
      margin-bottom: 50px;
      @include small-tablet {
        margin-bottom: 40px;
      }
      @include mobile {
        margin-top: 80px;
        grid-area: auto;
      }
    }

    .post-thumbnail {
      .attachment-post-thumbnail {
        border-radius: 8px;
      }
    }

    &__item {
      .item {
        &__photo, {
          border-radius: 8px;
        }

        &__date {
          font-size: 14px;
          margin-top: 8px;
          @include small-tablet {
            font-size: 12px;
          }
        }

        &__title {
          font-size: 24px;
          font-weight: 800;
          text-transform: uppercase;
          line-height: 120%;
          margin: 4px 0 0 0;
          @include tablet {
            font-size: 20px;
          }
          @include mobile {
            font-size: 16px;
          }

          .item__link {
            color: var(--light-color);
            text-decoration: none;
            width: 30px;
          }
        }
      }
    }
  }

  .pagination {
    .nav-links {
    display: flex;
    justify-content: center;
      margin-bottom: 47px;
      @include small-tablet {
        margin-bottom: 40px;
      }
    .page-numbers {
      border-radius: 3px;
      border: 1px solid var(--main-color);
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--light-color);
      text-decoration: none;
      &:not(:last-child) {
        margin-right: 13px;
      }
      &.current {
        justify-content: center;
        color: var(--background-color);
        background-color: var(--main-color);
      }
      &.prev, &.next {
        border: none;
        color: var(--main-color);
        font-weight: 800;
      }
    }
    }
  }
  .article {
    &__title {
      @include mobile {
        margin-top: 80px;
      }
    }
    &__date {
      font-size: 14px;
      margin-top: 8px;
      @include small-tablet {
        font-size: 12px;
      }
    }
    .info__photo {
      border-radius: 8px;
      float: left;
      margin-right: 38px;
      margin-bottom: 38px;
      @include mobile {
        margin-right: 0;
      }
    }
    .nav-links {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;
      @include mobile {
        margin-bottom: 28px;
      }
      .nav-previous, .nav-next {
        position: relative;
        display: flex;
        &:before {
          content: '';
          width: 19px;
          flex: 0 0 19px;
          height: 17px;
          margin-right: 20px;
          margin-top: 5px;
          background-image: url('/wp-content/themes/blackriver/front/app/img/arrow.svg');
          background-size: contain;
          background-repeat: no-repeat;
          @include small-tablet {
            flex: 0 0 14px;
            height: 11px;
            margin-right: 10px;
          }
        }
      }
      .nav-next {
        flex-direction: row-reverse;
        &:before {
          margin-left: 20px;
          margin-right: 0;
          transform: rotate(180deg);
          @include small-tablet {
            margin-right: 0;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .error-404.not-found {
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: calc(100vh - 360px);
      .not-found__title {
        margin: 0;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: var(--background-color);
        background-image: linear-gradient(90deg, #CDA130, #FEFEFE);
      }
    }
  }
}
