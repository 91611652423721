.site-container {
  @include mobile {
    position: relative;
  }
}
.main {
  //min-height: calc(100vh - 309px);
  display: flex;
  align-items: center;
  height: 100%;
  @include tablet {
    //min-height: calc(100vh - 315px);
  }
  @include small-tablet {
    //min-height: calc(100vh - 322px);
  }
  @include mobile {
    //min-height: calc(100vh - 411px);
    margin-top: 0;
  }
  &.constructor {
    @include mobile {
      margin-bottom: auto;
    }
  }
  .container {
    width: 100%;
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 343px;
    gap: 10px;
    width: 100%;
    height: 100%;
    @include tablet {
      margin-bottom: 40px;
    }
    @include small-tablet {
      grid-template-columns: 1fr 310px;
    }
    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
    .about {
      grid-area: 1 / 1 / 3 / 2;
      border-radius: 20px;
      background: #E5E5FF;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 30px;
      height: 650px;
      overflow: hidden;
      @include tablet {
        height: 520px;
      }
      @include small-tablet {
        height: 650px;
      }
      @include mobile {
        padding: 18px 16px;
        height: auto;
        background-position: 92px 35px;
        background-size: 388px;
      }
      &__img {
        position: absolute;
        right: 100px;
        bottom: -40px;
        width: 525px;
        @include tablet {
          right: -100px;
          width: 480px;
        }
        @include mobile {
          width: auto;
          top: 65px;
          right: -90px;
        }
      }
      &__title {
        max-width: 590px;
        margin: 0;
        z-index: 3;
      }
      &__text {
        font-size: 20px;
        font-weight: 600;
        margin-top: 6px;
        max-width: 329px;
        z-index: 3;
        @include tablet {
          font-size: 18px;
        }
        @include small-tablet {
          font-size: 16px;
        }
        @include mobile {
          font-weight: 500;
          line-height: 130%;
          max-width: 278px;
        }

      }
      &__tradein {
        margin-top: 103px;
        border-radius: 20px;
        padding: 20px;
        background: rgba(255, 255, 255, 0.25);
        max-width: 383px;
        font-size: 14px;
        line-height: 120%;
        z-index: 3;
        @include tablet {
          font-size: 12px;
          margin-top: 53px;
        }
        @include small-tablet {
          margin-top: 126px;
        }
        @include mobile {
          max-width: 210px;
          margin-top: 30px;
          background-color: transparent;
          border-radius: 0;
          padding: 0;
          font-weight: 400;
        }
        .tradein {
          &__title {
            font-size: 20px;
            font-weight: 600;
            margin: 0 0 7px 0;
            @include tablet {
              font-size: 18px;
            }
          }
          &__text {

          }
        }
      }
      &__deal {
        margin-top: 22px;
        padding: 20px;
        box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
        border-radius: 20px;
        background: var(--light-color);
        max-width: 455px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        z-index: 4;
        @include small-tablet {
          padding: 12px 10px;
          font-size: 12px;
        }
        &.active {
          position: absolute;
          bottom: 20px;
          left: 20px;
          width: calc(100% - 40px);
          height: 366px;
          max-width: 100%;
          transition: .1s;
          @include mobile {
            position: initial;
            width: 100%;
            height: auto;
          }
          .deal__btn .btn__icon {
              &:after {
                transform: translate(3px) rotate(45deg);
                top: 9px;
              }
              &:before {
                transform: translate(-3px) rotate(-45deg);
                top: 9px;
              }
          }
        }
        .deal {
          &__title {
            font-size: 20px;
            margin: 0;
            @include tablet {
              font-size: 18px;
            }
            @include small-tablet {
              font-size: 15px;
            }
          }
          &__toggle {
            margin-top: 6px;
            font-size: 14px;
            line-height: 130%;
            @include tablet {
              font-size: 12px;
            }
          }
          &__btn {
            height: 30px;
            padding: 6px 10px 7px 10px;
            font-size: 13px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 0 0 85px;
            @include mobile {
              padding: 5px 10px;
              font-size: 13px;
              flex: 0 0 auto;
            }
            .btn__icon {
              display: block;
              flex: 0 0 20px;
              height: 20px;
              border-radius: 16px;
              background-color: var(--inter-color);
              margin-left: 6px;
              margin-top: 2px;
              position: relative;
              transition: .3s;
              @include mobile {
                display: none;
              }
              &:after, &:before {
                content: '';
                position: absolute;
                width: 9px;
                height: 2px;
                border-radius: 1px;
                background-color: var(--inter-second);
                transform: rotate(45deg);
                left: 3px;
                top: 10px;
                transition: .3s;
              }
              &:before {
                transform: rotate(-45deg);
                left: 9px;
              }
            }
          }
          &__toggle {
           &--full {
             display: none;
             font-size: 14px;

             @include tablet {
               font-size: 12px;
             }
             &.active {
               display: block;
             }
             ul {
               padding: 0 0 0 13px;
               margin: 5px 0;
             }
           }
          }
        }
      }
    }
    .calc {
      grid-area: 1 / 2 / 2 / 3;
      background: var(--tel);
      border-radius: 20px;
      padding: 20px 16px;
      box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      color: var(--light-color);
      @include small-tablet {
        //background: var(--tel) url("/wp-content/themes/vsedoma/front/app/img/calc.svg") no-repeat 155px 92px/200px;
      }
      @include mobile {
        max-width: 100%;
        margin: 16px 16px 0 ;
      }
      &__title {
        font-size: 20px;
        z-index: 3;
        @include tablet {
          font-size: 18px;
          max-width: 245px;
        }
        @include mobile {
          font-weight: 600;
          max-width: 280px;
        }
      }
      &__img {
        position: absolute;
        bottom: 0;
        right: -10px;
        @include mobile {
          bottom: -40px;
        }
      }

      &__text {
        width: 200px;
        font-size: 15px;
        z-index: 3;
        @include tablet {
          font-size: 13px;
        }
      }
      &__btn {
        margin-top: auto;
        background-color: var(--inter-second);
        width: 100%;
        color: var(--light-color);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        border-radius: 16px;
        text-decoration: none;
        font-size: 16px;
        z-index: 3;
        @include mobile {
          margin-top: 27px;
        }
        &:hover {
          background-color: #605DFA;
        }
        &:active {
          background-color: var(--inter-second);
        }
      }
    }
    .passport {
      grid-area: 2 / 2 / 3 / 3;
      padding: 20px 16px;
      background:var(--light-color);
      border-radius: 20px;
      box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: hidden;
      @include mobile {
        margin: 16px 16px 38px 16px ;
      }
      &__title {
        font-size: 22px;
        margin: 0;
        position: relative;
        z-index: 3;
        @include mobile {
          font-size: 18px;
        }
      }
      &__text {
        margin-top: 10px;
        max-width: 208px;
        font-size: 13px;
        position: relative;
        z-index: 3;
        @include tablet {
          font-size: 13px;
        }
        @include mobile {
          max-width: 100%;
        }
      }
      &__img {
        position: absolute;
        right: 0px;
        top: 0px;
        @include tablet {
          top: -46px;
        }
        @include small-tablet {
          top: 0;
          right: -22px;
        }
        @include mobile {
          position: relative;
          margin-top: -80px;
          top: 0;
          right: 0;
          transform: translateX(-40px);
          width: 100%;
        }
      }
      &__btn {
        margin-top: auto;
        width: 100%;
        border-radius: 20px;
        font-size: 16px;
        padding: 20px 16px;
        background-color: rgba(160, 161, 253, .15);
        color: var(--inter-second);
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        z-index: 3;
        @include mobile {
          margin-top: -80px;
        }
        &:hover {
          background: rgba(147, 148, 226, 0.30);
          color: #605DFA;
        }
        &:active {
          background-color: var(--tel);
          color: var(--light-color);
        }
      }
    }
  }
  .constructor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 20px;
    @include tablet {
      font-size: 13px;
      gap: 10px;
    }
    @include mobile {
      grid-template-columns: 100%;
    }
    &__tip--mobile {
      display: none;
      @include mobile {
        display: block;
        width: 100%;
        background-color: var(--light-color);
        padding: 19px 16px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background-image: url("/wp-content/themes/vsedoma/front/app/img/main-hero.svg");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          z-index: -1;
          border-radius: 15px;
        }
        &.hide {
          display: none;
        }
      }
      .tip__title {
        color: var(--background-block);
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 36px */
        text-transform: uppercase;
        margin: 0;
        z-index: 1;
      }
      .tip__info {
        font-size: 13px;
        font-weight: 500;
        color: var(--background-block);
        width: 216px;
        z-index: 1;
      }
      .tip__text {
        margin-top: 180px;
        font-size: 13px;
        font-weight: 500;
        color: var(--background-block);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
        background-color: var(--light-color);
        padding: 10px 12px;
        border-radius: 15px;
        z-index: 1;
        .text__title {
          margin: 0 0 8px 0;
          font-size: 18px;
          font-weight: 600;
          color: var(--tel)
        }
      }
    }
    &__need {
      display: flex;
      margin-top: 19px;
      &.warn {
        .need__title {
          display: none;
        }
        .need__warn {
          display: flex;
          align-items: center;
          &:before {
            content: '';
            flex: 0 0 18px;
            height: 18px;
            background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' width='18' height='18' rx='9' fill='%23EB5757'/%3E%3Cpath d='M9.5 6.86328V9.39453' stroke='white' stroke-width='1.06875' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.76851 3.90935L4.12913 11.9246C4.0549 12.0528 4.01575 12.1984 4.01563 12.3466C4.0155 12.4947 4.05441 12.6403 4.12843 12.7687C4.20246 12.8971 4.30899 13.0037 4.4373 13.0778C4.56561 13.152 4.71119 13.191 4.85938 13.191H14.1381C14.2863 13.191 14.4319 13.152 14.5602 13.0778C14.6885 13.0037 14.795 12.8971 14.8691 12.7687C14.9431 12.6403 14.982 12.4947 14.9819 12.3466C14.9818 12.1984 14.9426 12.0528 14.8684 11.9246L10.229 3.90935C10.1549 3.7813 10.0484 3.67499 9.92022 3.60109C9.79205 3.52718 9.6467 3.48828 9.49875 3.48828C9.3508 3.48828 9.20545 3.52718 9.07728 3.60109C8.94911 3.67499 8.84262 3.7813 8.76851 3.90935V3.90935Z' stroke='white' stroke-width='1.06875' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.5 11.9258C9.84949 11.9258 10.1328 11.6425 10.1328 11.293C10.1328 10.9435 9.84949 10.6602 9.5 10.6602C9.15051 10.6602 8.86719 10.9435 8.86719 11.293C8.86719 11.6425 9.15051 11.9258 9.5 11.9258Z' fill='white'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            margin-right: 10px;
          }
        }
      }
      .need__title {
        margin: 0;
      }
      .need__warn {
        display: none;
        color: var(--warn-color);
        font-size: 12px;
        font-weight: 400;
        flex: 0 0 100%;
      }

    }
    &__control {
      display: none;
      @include mobile {
        justify-content: space-between;
        align-items: center;
        padding: 0 16px 20px 16px;
        height: 60px;
        width: 100%;
        background-color: var(--active);
        position: absolute;
        left: 0;
        color: var(--tel);
      }
      &.flat {
        @include mobile {
          display: flex;
        }
      }
      &.pass {
        @include mobile {
          display: flex;
        }
      }
      .control__btn.hide {
        display: none;
      }
    }
    &__column {
      &.column--params {
        padding: 20px 30px 0 30px;
        @include tablet {
          padding: 0;
        }
        @include mobile {
          //height: 100vh;
          padding: 0 16px;
        }
        &.hide {
          @include mobile {
            transition-delay: .7s;
            display: none;
          }
        }
        .column {
          &__title {
            font-size: 22px;
            font-weight: 600;
            display: flex;
            margin: 10px 0 0 0;
            @include mobile {
              font-size: 18px;
              font-weight: 800;
              position: relative;
              display: none;
              //&:before {
              //  content: '';
              //  position: absolute;
              //  width: 100vw;
              //  border-bottom: 1px solid var(--inter-color);
              //  bottom: -8px;
              //  left: -16px;
              //  right: 0;
              //}
            }
            &.active {
              @include mobile {
                display: block;
              }
            }
          }
          &__tip {
            color: var(--inter-second);
            border: none;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 14px;
            padding: 2px 7px;
            background-color: var(--inter-color);
            border-radius: 50%;
            margin-left: 10px;
            cursor: pointer;
            display: none;
            height: fit-content;
            &.active {
              display: flex;
              @include mobile {
                display: none;
              }
            }
          }
        }
        .constructor__tip {
          margin-top: 14px;
          margin-bottom: 20px;
          @include mobile {
            display: none;
          }

          //&--mobile {
          //  display: none;
          //  @include mobile {
          //    display: block;
          //    width: calc(100% + 32px);
          //    background-color: var(--light-color);
          //    padding-left: 16px;
          //    padding-right: 16px;
          //    transform: translateX(-16px);
          //    border-bottom-left-radius: 15px;
          //    border-bottom-right-radius: 15px;
          //    position: relative;
          //    &:before {
          //      content: '';
          //      position: absolute;
          //      width: 100%;
          //      height: 100%;
          //      background-image: url("/wp-content/themes/vsedoma/front/app/img/main-hero.svg");
          //      background-size: contain;
          //      background-position: center;
          //      background-repeat: no-repeat;
          //    }
          //  }
          //  .tip__title {
          //    color: var(--background-block);
          //    font-size: 30px;
          //    font-style: normal;
          //    font-weight: 700;
          //    line-height: 120%; /* 36px */
          //    text-transform: uppercase;
          //    margin: 0;
          //  }
          //  .tip__info {
          //    font-size: 13px;
          //    font-weight: 500;
          //    color: var(--background-block);
          //    width: 216px;
          //  }
          //  .tip__text {
          //    margin-top: 180px;
          //    font-size: 13px;
          //    font-weight: 500;
          //    color: var(--background-block);
          //    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
          //    background-color: var(--light-color);
          //    padding: 10px 12px;
          //    border-radius: 15px;
          //    .text__title {
          //      margin: 0 0 8px 0;
          //      font-size: 18px;
          //      font-weight: 600;
          //      color: var(--tel)
          //    }
          //  }
          //}
        }
      }
      &.column--offer {
        box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
        background-color: var(--light-color);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        position: relative;
        @include tablet {
          height: 676px;
        }
        @include mobile {
          transition: .4s;
          position: absolute;
          top: 42px;
          left: 0;
          height: calc(100vh - 100px);
          width: 100%;
          transform: translateY(200vh);
          align-items: flex-start;
        }
        &.flats {
          @include mobile {
            transform: translateY(0vh);
            position: relative;
            height: 215px;
            margin-bottom: -20px;
            margin-top: -32px;
          }
          .offer__wrapper .offer__list {
            @include mobile {
              height: 223px;
              display: flex;
              padding-bottom: 0;
            }
            .list__item {
              @include mobile {
                flex: 0 0 197px;
                height: 188px;
              }
              .item__title {
                @include mobile {
                  padding: 4px 10px;
                }
                .item__room {
                  @include mobile {
                    font-size: 18px;
                  }
                }
                .item__area {
                  @include mobile {
                    font-size: 18px;
                  }
                  sup {
                    @include mobile {
                      font-size: 13px;
                    }
                  }
                }
              }
              .item__img {
                @include mobile {
                  bottom: -17px;
                  right: -16px;
                }
              }
              .item__btn {
                @include mobile {
                  margin-top: auto;
                }
              }
              .item__addr, .item__price {
                @include mobile {
                  margin-left: 0;
                }
              }
            }
          }
        }
        &.flats-active {
          align-items: flex-start;
          padding-top: 10px;
          height: auto;
          @include mobile {
            margin-top: 0;
            animation: toggletrack .5s forwards;
          }
          .offer__wrapper {
            .offer__controls .controls__option {
              display: none;
              @include mobile {
                display: block;
              }

            }
            .offer__tap {
              display: none;
            }
            .offer__controls {
              .btn--small {
                display: none;
              }
            }
            .offer__list {
              padding-bottom: 20px;
            }
          }
          .offer__wrapper .offer__list {
            @include mobile {
              height: 726px;
              display: grid;
            }
            .list__item {
              @include mobile {
                flex: none;
                height: 188px;
              }
              .item__title {
                @include mobile {
                  padding: 10px 20px;
                }
                .item__room {
                  @include mobile {
                    font-size: 30px;
                  }
                }
                .item__area {
                  @include mobile {
                    font-size: 30px;
                  }
                  sup {
                    @include mobile {
                      font-size: 22px;
                    }
                  }
                }
              }
              .item__addr, .item__price {
                @include mobile {
                  margin-left: 10px;
                }
              }
              .item__img {
                @include mobile {
                  bottom: 0;
                  right: 0;
                }
              }
              .item__btn {
                @include mobile {
                  margin-top: auto;
                }
              }
            }
          }
        }

        .constructor__tip {
          flex: 0 0 343px;
          @include mobile {
            margin-top: 16px;
          }
          @include mobilemini {
            flex: 0 0 300px;
          }
        }
        .column__loading {
          display: none;
          &.active {
            display: block;
          }
        }
        .offer__tap {
          display: none;
          @include mobile {
            border: none;
            display: block;
            width: fit-content;
            position: absolute;
            width: 100%;
            height: 70px;
            background-color: transparent;
            left: 0;
            top: 0;
          }
        }
        .offer__wrapper {
          display: none;
          &.active {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 30px 0 0 0;
            height: 100%;
            @include mobile {
              height: calc(100vh - 113px);
            }
          }
          .offer__controls {
            display: flex;
            justify-content: space-between;
            padding: 0 40px;
            @include tablet {
              padding: 0 10px 0 16px;
            }

            &.hide {
              @include mobile {
                display: none;
              }
            }
            .controls__count {
              font-size: 18px;
              font-weight: 600;
              color: var(--background-block);
              flex: 0 0 550px;
              display: flex;
              align-items: center;
              @include mobile {
                flex: 0 0 240px;
                font-size: 15px;
              }
              .count__text {
                position: relative;
                display: flex;
                flex: 0 0 190px;
                @include mobile {
                  flex: 0 0 165px;
                }
                &:before {
                  content: '';
                  flex: 0 0 18px;
                  height: 18px;
                  margin-right: 10px;
                  background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center / contain;
                }
              }
              .count__value {
                font-weight: 500;
                font-size: 16px;
                color: var(--accent);
                background-color: var(--background-color);
                padding: 2px 10px;
                border-radius: 30px;
                margin-left: 10px;
                width: fit-content;
              }

            }
            .btn--small {
              display: none;
              @include mobile {
                display: block;
              }
              @include mobilemini {
                display: none;
              }
            }
            .controls__option {
              border: 0;
              background-color: transparent;
              padding: 0;
              display: none;
              @include mobile {
                display: none;
              }
            }

          }
          .new__plate {
            display: none;
            margin: 0 26px 0 16px;
            background-color: var(--active);
            .plate__row {
              align-items: center;
              .plate__title:before {
                background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center / contain;
              }
              .row__btn {
                border-radius: 23px;
                background: rgba(160, 161, 253, 0.15);
                padding: 6px 10px;
                color: var(--background-block);
                font-size: 13px;
                font-weight: 600;
                border: none;
                &.active {
                  background-color: var(--inter-second);
                  color: var(--light-color);
                }
              }
            }
            .plate__form {
              .form__address {
                border-radius: 10px;

              }
            }
            &.active {
              @include mobile {
                display: block;
                flex: 0 0 auto;
              }
            }
          }
          .offer__list {
            display: grid;
            grid-template-columns: 100%;
            gap: 10px;
            padding: 0 30px;
            margin: 15px 0 0 0;
            list-style: none;
            width: 99%;
            overflow-y: scroll;
            height: 655px;
            align-content: start;
            @include tablet {
              padding: 0 16px;
              height: 604px;
            }
            @include mobile {
              height: 670px;
            }
            &::-webkit-scrollbar {
              background-color: transparent;
              width: 6px;
            }
            &::-webkit-scrollbar-track {
              display: none;
            }
            &::-webkit-scrollbar-thumb  {
              border-radius: 30px;
              height: 2px;
              background: var(--old-543534, rgba(0, 0, 0, 0.05));
            }
            &.hide {
              display: none;
            }
            .list__item {
              border-radius: 15px;
              height: 189px;
              background-color: var(--tel);
              position: relative;
              padding: 16px 16px 15px;
              display: flex;
              flex-direction: column;
              overflow: hidden;
            }
            .item__title {
              display: flex;
              background-color: var(--light-color);
              padding: 11px 20px;
              border-radius: 45px;
              font-size: 35px;
              font-weight: 600;
              width: fit-content;
              margin: 0;
              sup {
                margin-left: 2px;
                font-size: 22px;
              }
              .item__area {
                margin-left: 10px;
                color: var(--accent);
              }
              .item__room {

              }
            }
            .item__addr, .item__price {
              margin-top: 6px;
              color: var(--light-color);
              font-size: 13px;
              font-weight: 500;
              letter-spacing: -0.13px;
              margin-left: 20px;
              max-width: 330px;
              @include mobile {
                max-width: 220px;
              }
            }
            .item__btn {
              margin-top: 5px;
              border-radius: 25px;
              padding: 8px 15px;
              background-color: #F1EDFF;
              color: var(--inter-second);
              font-size: 13px;
              font-weight: 600;
              width: fit-content;
              cursor: pointer;
              z-index: 3;
              @include mobile {
                padding: 5px 10px;
              }
            }
            .item__img {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
          .offer__error {
            display: none;
            padding: 15px 40px;
            @include mobile {
              padding: 15px 16px;
            }
            &.active {
              display: block;
            }
            .offer__tip {
              flex-wrap: wrap;
              background-color: #F5EEEF;
              color: var(--warn-color);
              width: 320px;
              .tip__title {
                margin: 0 0 10px 0;
                flex: 0 0 90%;
              }
              .tip__warn {
                flex: 0 0 18px;
                height: 18px;
                background: url("/wp-content/themes/vsedoma/front/app/img/warn.svg") no-repeat center / contain;
              }
            }
          }
        }
        .pass__wrapper {
          display: none;
          width: 100%;
          padding: 10px 30px;
          @include tablet {
            padding: 0 16px;
          }
          @include mobile {
            padding-top: 30px;
            padding-bottom: 30px;
            background-color: #FCFCFC;
          }
          &.active {
            display: block;
          }
          .pass {
            &__control {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .control__title {
                font-size: 18px;
                font-weight: 600;
                margin: 0;
              }
              .control__back {
                font-weight: 500;
                padding: 6px 10px;
                &.hide {
                  display: none;
                }
                @include mobile {
                  display: none;
                }
              }
            }
            &__my {
              margin-top: 10px;
              padding: 10px;
              background-color: var(--inter-color);
              border-radius: 15px;
              .my__row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                &:not(:first-child) {
                  margin-top: 8px;
                  color: var(--tel);
                }
              }
              .row__title {
                padding-bottom: 12px;
                position: relative;
                margin: 0;
                &:before {
                  content: '';
                  width: 209px;
                  height: 1px;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  background-color: rgba(102, 103, 171, .1);
                }

              }
              .row__btn {
                display: none;
                margin-bottom: 12px;
                @include mobile {
                  display: block;
                }
              }
            }
            &__flat {
              margin-top: 10px;
              &.hide {
                display: none;
              }
              .flat__item {
                border-radius: 15px;
                height: 119px;
                background-color: var(--tel);
                position: relative;
                padding: 12px 18px 10px;
                display: flex;
                flex-direction: column;
                overflow: hidden;
                .item__title {
                  margin: 7px 0 0 0;
                  display: flex;
                  background-color: var(--light-color);
                  padding: 8px 20px 5px;
                  border-radius: 45px;
                  font-size: 25px;
                  font-weight: 600;
                  width: fit-content;
                  sup {
                    margin-left: 2px;
                    font-size: 18px;
                  }
                  .item__area {
                    margin-left: 10px;
                    color: var(--accent);
                  }
                  .item__room {

                  }
                }
                .item__price {
                  margin-top: 8px;
                  color: var(--light-color);
                  font-size: 13px;
                  font-weight: 500;
                  letter-spacing: -0.13px;
                  margin-left: 20px;
                }
                .item__choose {
                  border-radius: 27px;
                  padding: 2px 8px 3px 8px;
                  color: var(--light-color);
                  background-color: var(--inter-color);
                  width: fit-content;
                }
                .item__img {
                  position: absolute;
                  bottom: -15px;
                  right: 0;
                }
              }
            }
            &__info {
              margin-top: 10px;
              border-radius: 15px;
              border: 1px solid var(--inter-color);
              padding: 10px 0;
              background-color: var(--accent);
              &.active {
                width: 100%;
                margin-top: 10px;
                height: 855px;
                left: 0;
                top: 0;
                padding: 0 0 30px 0;
                background-color: var(--light-color);
                @include mobile {
                  position: relative;
                  margin-top: 10px;
                  padding: 0;
                  height: auto;
                }
                .info__container {
                  padding-top: 10px;
                }
              }
              .info {
                &__container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 10px;
                  background-color: var(--accent);
                  border-top-left-radius: 15px;
                  border-top-right-radius: 15px;
                }
                &__title {
                  margin: 0;
                  position: relative;
                  color: var(--light-color);
                  display: flex;
                  align-items: center;
                  font-size: 15px;
                  font-weight: 600;
                  flex: 0 0 170px;
                  @include mobilemini {
                    flex: 0 0 155px;
                  }
                  &:before {
                    content: '';
                    margin-right: 10px;
                    flex: 0 0 18px;
                    height: 18px;
                    background: url("/wp-content/themes/vsedoma/front/app/img/Document.svg") no-repeat center / contain;
                  }
                }
                &__btn {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex: 0 0 108px;
                  &:hover {
                    background-color: #B3B3D5;
                  }
                  &.active {
                    background-color: var(--light-color);
                    color: var(--inter-second);
                    .btn__icon {
                      background-color: var(--inter-color);
                      &:before, &:after {
                        transform: rotate(45deg);
                        top: 9px;
                      }
                      &:after {
                        transform: rotate(-45deg);
                      }
                    }
                  }
                  .btn__icon {
                    display: block;
                    flex: 0 0 20px;
                    height: 20px;
                    border-radius: 16px;
                    background-color: var(--inter-color);
                    margin-left: 10px;
                    margin-top: 2px;
                    position: relative;
                    transition: .3s;
                    &:after, &:before {
                      content: '';
                      position: absolute;
                      width: 9px;
                      height: 2px;
                      border-radius: 1px;
                      background-color: var(--inter-second);
                      transform: rotate(45deg);
                      left: 3px;
                      top: 10px;
                      transition: .3s;
                    }
                    &:before {
                      transform: rotate(-45deg);
                      left: 9px;
                    }
                  }
                }
                &__wrapper {
                  display: none;
                  &.active {
                    flex: 0 0 100%;
                    height: 557px;
                    display: block;
                    border-bottom-right-radius: 15px;
                    border-bottom-left-radius: 15px;
                    background-color: var(--light-color);
                    @include mobile {
                      height: auto;
                      padding-bottom: 10px;
                    }
                  }
                  .info__text {
                    padding: 20px 10px 10px;
                    background-color: var(--accent);
                    color: var(--light-color);
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                    font-size: 12px;
                    position: relative;
                    &:before {
                      content: '';
                      width: calc( 100% - 20px);
                      height: 1px;
                      background-color: var(--light-color);
                      position: absolute;
                      opacity: .3;
                      top: 10px;
                      right: 10px;

                    }
                    .info__column {
                      &:nth-child(2) {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                      }
                      .info__price {
                        font-size: 15px;
                        font-weight: 600;
                      }
                    }

                  }
                  .info__next {
                    padding: 0 12px;
                    .info__title {
                      color: var(--accent);
                      margin: 12px 0 0 0;
                      display: block;
                      font-size: 15px;
                      font-weight: 600;
                      text-align: center;
                    }
                    .info__steps {
                      display: block;
                      width: 343px;
                      margin: 0 auto;
                      @include mobile {
                        width: auto;
                      }
                      .steps__item {
                        display: flex;
                        padding: 12px 0;
                        &:not(:last-child) {
                          border-bottom: 1px solid rgba(147, 148, 226, 0.30);
                        }
                        .item__num {
                          flex: 0 0 43px;
                          padding: 4px 10px;
                          background-color: var(--tel);
                          color: var(--light-color);
                          font-size: 12px;
                          font-weight: 400;
                          border-radius: 50%;
                          text-align: center;
                          height: fit-content;
                          line-height: 140%;
                          .num__val {
                            font-size: 13px;
                            font-weight: 600;
                          }
                        }
                        .item__text {
                          margin-left: 8px;
                          font-size: 13px;
                          font-weight: 500;
                          color: var(--text-color);
                          flex: 0 0 calc(100% - 43px);
                        }
                      }
                    }
                    .info__img {
                      display: block;
                      margin: 0 auto 0 auto;
                    }
                    .info__text--final {
                      font-size: 15px;
                      font-weight: 600;
                      max-width: 343px;
                      text-align: center;
                      background-color: #FF8F51;
                      padding: 8px 10px;
                      border-radius: 10px;
                      color: var(--light-color);
                      margin: 0 auto;
                    }
                    .info__docs {
                      margin: 12px auto 0 auto;
                      border-radius: 15px;
                      border: 1px solid rgba(160, 161, 253, 0.15);
                      padding: 6px 10px;
                      display: flex;
                      font-size: 12px;
                      max-width: 343px;
                      color: var(--tel);
                      font-weight: 400;
                      .docs__check {
                        background: url("/wp-content/themes/vsedoma/front/app/img/checkdocs.svg") no-repeat center / contain;
                        flex: 0 0 30px;
                        margin-right: 8px;
                      }
                    }
                    .info__help {
                      color: var(--text-color);
                      margin: 12px auto 0 auto;
                      max-width: 343px;
                    }
                    .info__send {
                      margin: 12px auto 0 auto;
                      max-width: 343px;
                      display: flex;
                      justify-content: space-between;
                      .send__link {
                        padding: 0 10px;
                        flex: 0 0 47%;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 15px;
                        background-color: rgba(160, 161, 253, 0.15);
                        color: var(--inter-second);
                        font-size: 13px;
                        font-weight: 600;
                        text-decoration: none;
                        svg {
                          margin-right: 6px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
         .pass__form {
           margin-top: 30px;
           font-size: 13px;
           font-weight: 500;
           margin-bottom: 20px;
           .form__wrapper {
             margin-top: 10px;
             .form__tabs {
               display: grid;
               grid-template-columns: repeat(3, 1fr) ;
               gap: 10px;
               .form__tab {
                 font-size: 15px;
                 padding: 9px 21px;
                 font-family: var(--font-family);
                 color: var(--tel);
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 @include tablet {
                   font-size: 13px;
                   padding: 9px 10px;
                 }
                 @include mobilemini {
                   padding: 8px 15px;
                 }
                 svg {
                   margin-right: 8px;
                 }
                 &.active {
                   color: var(--light-color);
                   background-color: var(--tel);
                   svg path{
                     fill: var(--light-color);
                   }
                 }
               }
             }
             .form__input {
               margin-top: 10px;
               width: 100%;
               padding: 10px 15px;
               font-size: 18px;
               font-weight: 500;
               border-radius: 10px;
               border: 1px solid var(--old-543534, rgba(0, 0, 0, 0.05));
               font-family: Manrope;
               &:focus {
                 outline: 1px solid var(--inter-second);
               }
             }
             ::-webkit-input-placeholder {color: rgba(37, 40, 44, .3);}
             :-moz-placeholder           {color: rgba(37, 40, 44, .3);}
             ::-moz-placeholder          {color: rgba(37, 40, 44, .3);}
             :-ms-input-placeholder      {color: rgba(37, 40, 44, .3);}
             .form__sub {
               margin-top: 10px;
               display: flex;
               align-items: center;
               justify-content: space-between;
               @include tablet {
                 flex-wrap: wrap;
                 align-items: center;
                 justify-content: flex-start;
               }
               .sub__text {
                 font-size: 12px;
                 font-weight: 400;
                 line-height: 120%;
                 @include tablet {
                   flex: 0 0 100%;
                   order: 1;
                   margin-top: 14px;
                 }
                 a {
                   color: #4762AE;
                   text-decoration: none;
                 }
               }
               .form__submit {
                 flex: 0 0 208px;
                 padding: 16px;
                 border-radius: 10px;
                 background-color: var(--inter-second);
                 color: var(--light-color);
                 font-size: 16px;
                 font-weight: 600;
                 font-family: Manrope;
                 cursor: pointer;
                 @include tablet {
                  flex: 0 0 100%;
                 }
               }
             }
           }
         }
        }
        .finish__wrapper {
          padding: 32px 16px 30px 16px;
          display: none;
          &.active {
            display: block;
          }
          .finish__info {
            border-radius: 20px;
            background-color: var(--accent);
            background-image: url(/wp-content/themes/vsedoma/front/app/img/lead.svg);
            background-position: 100% 151%;
            background-repeat: no-repeat;
            padding: 16px 15px;
            height: 252px;
            .finish__title {
              font-size: 18px;
              font-weight: 600;
              color: var(--light-color);
              margin: 0 0 10px 0;
            }
            .finish__text {
              font-size: 12px;
              font-weight: 400;
              color: var(--light-color);
              max-width: 206px;
            }
          }
          .finish__link {
            background: var(--15, rgba(160, 161, 253, 0.15));
            border-radius: 10px;
            text-align: center;
            width: 100%;
            color: #6567FE;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            padding: 16px;
            margin-bottom: 15px;
            justify-content: center;
            margin-top: 15px;
            text-decoration: none;
          }
          .finish__pass {
            margin-top: 20px;
            background-color: rgba(160, 161, 253, 0.15);
            font-size: 16px;
            font-weight: 700;
            width: 100%;
            text-align: center;
            color: var(--active);
            text-decoration: none;
            padding: 16px;
            border-radius: 10px;
            display: block;
          }
          .finish__info--pass {
            display: block;
          }
        }
      }
      .column__money {
        margin-top: 18px;
        width: 100%;
        background-color: var(--inter-color);
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 8px;
        border-radius: 16px;
        cursor: pointer;
        @include mobile {
          margin-top: 8px;
        }
        .money__title {
          font-size: 13px;
          font-weight: 600;
          color: var(--inter-second);
        }
        .money__text {
          font-size: 12px;
          font-weight: 400;

          color: var(--tel);
        }
      }
    }
    &__form {
      border-radius: 15px;
      padding: 15px 20px;
      background-color: var(--inter-color);
      margin-top: 16px;
      height: fit-content;
      position: relative;
      @include tablet {
        padding: 10px;
      }
      @include mobile {
        margin-top: 20px;
        padding-bottom: 20px;
      }
      &.plate {
        background-color: var(--active);
        border: 1px solid var(--active);
      }
      &.warn {
        background-color: rgba(235, 87, 87, 0.05);
        border: 1px solid var(--warn-color);
        .own__plate {
          border: 1px solid var(--warn-color);
          .plate__info .info__text {
            color: var(--warn-color);
          }
        }
      }
    }
    &__own {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @include tablet {
        padding-bottom: 30px;
      }
      @include mobile {
        padding-bottom: 0;
      }
    }
    &__buy {
        .new__plate {
          &.finish {
            background-color: var(--tel);
            color: var(--light-color);
            .plate__title:before {
              background: url("/wp-content/themes/vsedoma/front/app/img/Iconly2.svg") no-repeat center / contain;
            }
            .plate__info .info__finish {
              .finish__float, .finish__room, .finish__area, .finish__address, .finish__price {
                color: var(--light-color);
              }
            }
            .plate__btn {
              background-color: var(--light-color);
            }
          }
          &.active {
            background-color: var(--inter-color);
            .plate__title:before {
              background: url("/wp-content/themes/vsedoma/front/app/img/Iconly.svg") no-repeat center / contain;
            }
            .info__finish {
              .finish__float, .finish__room, .finish__area, .finish__address, .finish__price {
                color: var(--text-color);
              }
            }
          }
          .plate__title:before {
            background: url("/wp-content/themes/vsedoma/front/app/img/Iconly.svg") no-repeat center / contain;
          }
      }
    }

  }
  .own, .new {
    &__money,  &__month {
      flex: 0 0 49%;
      @include tablet {
        flex: 0 0 100%;
      }
      .money__title, .month__title {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 15px;
        &:before {
          content: '';
          flex: 0 0 18px;
          height: 18px;
          background: url("/wp-content/themes/vsedoma/front/app/img/money.svg") no-repeat center / contain;
          margin-right: 10px;
        }
      }
      .month__title:before {
        background: url("/wp-content/themes/vsedoma/front/app/img/month.svg") no-repeat center / contain;

      }
    }
    &__money {
      @include tablet {
        order: -2;
      }
    }
    &__input {
      padding: 10px 15px;
      border-radius: 10px;
      background-color: var(--light-color);
      border: 1px solid var(--inter-color);
      margin-top: 6px;
      width: 100%;
      font-family: var(--font-family);
      font-size: 16px;
      font-weight: 600;
      &:focus {
        outline: 1px solid var(--inter-second);
      }
    }
    &__capital {
      padding: 5px 10px;
      border-radius: 33px;
      background-color: var(--inter-color);
      margin-top: 10px;
      display: grid;
      grid-template-columns: 16px 1fr;
      align-items: center;
      position: relative;
      align-content: center;
      cursor: pointer;
      @include tablet {
        order: -1;
        margin-bottom: 10px;
      }
      &:before {
        content: '';
        height: 16px;
        background: url("/wp-content/themes/vsedoma/front/app/img/checkout.svg") no-repeat center / contain;
      }
      &.active {
        background-color: var(--tel);
        color: var(--light-color);
        &:before {
          background: url("/wp-content/themes/vsedoma/front/app/img/checkin.svg") no-repeat center / contain;
        }
      }
      .capital__title {
        margin-left: 10px;
      }
      .input--capital {
        display: none;
      }
    }
    &__plate {
      flex: 0 0 100%;
      margin-top: 10px;
      border-radius: 15px;
      padding: 15px 20px;
      background-color: var(--light-color);
      border: 1px solid var(--tel);
      position: relative;
      @include tablet {
        padding: 10px 12px;
      }
      &.warn {
        border: 1px solid var(--warn-color);
      }
      svg {
        position: absolute;
        right: -40%;
        bottom: -25%;
        display: none;
        &.active {
          display: block;
        }
      }
      .plate {
        &__title {
          position: relative;
          display: flex;
          align-items: center;
          flex: 0 0 70%;
          font-weight: 600;
          font-size: 15px;
          @include tablet {
            flex: 0 0 174px;
          }
          @include mobilemini {
            flex: 0 0 155px;
            font-size: 13px;
          }
          &:before {
            content: '';
            flex: 0 0 18px;
            height: 18px;
            background: url("/wp-content/themes/vsedoma/front/app/img/own.svg") no-repeat center / contain;
            margin-right: 10px;
          }
        }
        &__row {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        &__form {
          display: none;
          margin-top: 12px;
          flex-wrap: wrap;
          justify-content: space-between;
          &.active {
            display: flex;
          }
          .form {
            &__room,&__area, &__address {
              background-color: var(--light-color);
              border: 1px solid var(--inter-color);
              flex: 0 0 48%;
              border-radius: 10px;
              padding: 5px 15px;
              @include mobilemini {
                flex: 0 0 40%;
              }
            }
            &__area {
              display: flex;
              justify-content: center;
              align-items: center;
              @include mobilemini {
                flex: 0 0 40%;
              }
              &.active {
                outline: 1px solid var(--inter-second);
              }
              .form__input {
                border: none;
                flex: 0 0 60px;
                width: 60px;
                &:focus {
                  outline: none;
                }
                &.warn {
                  &::placeholder {
                    color: var(--warn-color);
                  }
                }
              }
              span {
                margin-right: 10px;
                //&:first-of-type {
                //  margin-left: 20px;
                //}
                &:nth-child(2) {
                  margin-right: 10px;
                }
              }
            }
            &__address {
              flex: 0 0 100%;
              position: relative;
              border: none;
              padding: 0;

              border-radius: 10px;
              margin-top: 10px;
              .form__input {
                width: 100%;
                border: 1px solid var(--inter-color);
                border-radius: 10px;
                font-size: 16px;
                padding: 7.5px 15px;
                font-size: 13px;
                &:focus {
                  outline: 1px solid var(--inter-second);
                }
                &.warn {
                  outline: 1px solid var(--warn-color);
                  &::placeholder {
                    color: var(--warn-color);
                  }
                }
              }
            }
          }
        }
        &__info {
          margin-top: 10px;
          flex: 0 0 100%;
          color: var(--tel);
          letter-spacing: -0.12px;
          @include mobile {
            font-size: 12px;
          }
          &.hide {
            .info__text {
              display: none;
            }
          }
          &.warn {
            .info__text {
              color: var(--warn-color);
            }
          }
          .info__finish {
            display: none;
          }
          &.finish {
            display: block;
            .info__finish {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .finish__area {
                flex: 0 0 50%;
                margin-left: 20px;
                @include tablet {
                  flex: 0 0 30%;
                }
              }
              .finish__address {
                flex: 1 0 70%;
                margin-top: 7px;
                @include tablet {
                  flex: 1 0 60%;
                }
              }
              .finish__room {
                margin-left: 20px;
              }
              .finish__price {
                flex: 0 0 25%;
                text-align: right;
                font-weight: 600;
                font-size: 15px;
                color: var(--text-color);
                margin-top: 7px;
                @include tablet {
                  flex: 0 0 35%;
                }
              }
            }
          }
        }
        &__btn {
          font-weight: 600;
          font-size: 13px;
          border-radius: 33px;
          padding: 6px 10px;
          background-color: var(--inter-color);
          color: var(--inter-second);
          border: none;
          cursor: pointer;
          &:hover {
            background-color: var(--active);
          }
          &.active {
            background-color: var(--inter-second);
            color: #fff;
            &:hover {
              background-color: #605DFA;
            }
          }
          &.disabled {
            background: rgba(160, 161, 253, 0.15);
            color: var(--background-block);
          }
        }
      }
    }
    &__info {
      margin-top: 17px;
      color: var(--tel);
      &.hide {
        opacity: 0;
        @include mobile {
          height: 41px;
        }
      }
    }
    &__stash {
      display: none;
      &.active {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 7px 12px 10px 12px;
        border-radius: 2px 2px 15px 15px;
        display: flex;
        font-size: 15px;
        align-items: center;
        justify-content: space-between;
        color: var(--light-color);
        background-color: var(--tel);
        outline: 1px solid var(--tel);
        span {

          &:nth-of-type(2) {
            @include small-tablet {
              flex: 0 0 83px;
            }
            @include mobile {
              text-align: right;
              flex: 1 0 113px;
            }
          }
        }
      }
      &.warn {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 7px 12px 10px 12px;
        border-radius: 2px 2px 15px 15px;
        display: flex;
        font-size: 15px;
        align-items: center;
        justify-content: space-between;
        color: var(--light-color);
        background-color: var(--warn-color);
        outline: 1px solid var(--warn-color);
      }
    }
  }
  .offer__late {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 16px;
    width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    transition: .6s;
    font-size: 13px;
    font-weight: 600;
    background-color: var(--inter-second);
    color: var(--light-color);
    bottom: 60px;
    &.hide {
      bottom: -100%;
      @include small-tablet {
        bottom: -100%;
      }
    }
    &.active {
      bottom: 60px;

    }
  }

}
.tip {
  display: none;
  padding: 10px 12px;
  border-radius: 15px;
  background-color: var(--inter-color);
  font-weight: 500;
  font-size: 13px;
  color: var(--tel);
  letter-spacing: -0.13px;
  &.active {
    display: flex;
  }
  &__close {
    flex: 0 0 20px;
    padding: 0;
    height: 20px;
    border: none;
    border-radius: 50%;
    background-color: var(--inter-color);
    background-image: url("/wp-content/themes/vsedoma/front/app/img/close.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

}
.form {
  margin-top: 80px;
  .container {
    background: var(--background-block);
    padding-top: 50px;
    padding-bottom: 50px;
    @include small-tablet {
      padding-top: 23px;
      padding-bottom: 32px;
    }
    form {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 959px;
      margin: 35px auto 0 auto;
      position: relative;
      @include small-tablet {
        padding: 0 41px;
      }
      @include mobile {
        padding: 0;
        margin-top: 10px;
      }
      label {
        flex: 0 0 425px;
        width: 425px;
        @include small-tablet {
          width: initial;
          flex: 0 0 45%;
        }
        @include mobile {
          flex: 0 0 100%;
          margin-top: 25px;
        }
        .form__name, .form__tel {
          margin-top: 8px;
          border-radius: 6px;
          border: 1px solid #374D57;
          background-color: var(--background-color);
          padding: 14px 8px;
          color: #6B818A;
          @include small-tablet {
            width: 100%;
          }
        }
        .wpcf7-not-valid-tip {
          font-size: 12px;
        }
      }
      .form__btn {
        margin: 35px auto 0 auto;
        padding: 10px 38px;
        font-size: 16px;
        @include small-tablet {
          margin-top: 28px;
        }
      }
      .wpcf7-spinner {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -40px;
        margin: 0 auto;
      }
      .wpcf7-response-output {
        flex: 0 0 100%;
        border: none;
        text-align: center;
        margin: 20px 0 25px;
        font-size: 14px;
        @include small-tablet {
          margin-bottom: 0;
        }
      }
    }
  }
  &__title {
    margin: 0;
    text-align: center;
  }
  &__text {
    text-align: center;
    font-size: 36px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 130%;
    @include tablet {
      font-size: 26px;
      line-height: 32px;
    }
    @include mobile {
      font-size: 20px;
      line-height: 24px;
    }
  }
  &__message {
    margin-top: 35px;
    text-align: center;
    @include small-tablet {
      margin-top: 24px;
    }
  }
  &__social {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include small-tablet {
      margin-top: 16px;
    }
    .social__item {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
}

.btn--small {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 5px 10px;
  background-color: var(--inter-color);
  color: var(--inter-second);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--active);
  }
  &.active {
    background-color: var(--inter-second);
    color: #fff;
    &:hover {
      background-color: #605DFA;
    }
  }
  &.disabled {
    background: rgba(160, 161, 253, 0.15);
    color: var(--background-block);
  }
}
.btn--white {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 5px 10px;
  background-color: var(--light-color);
  color: var(--inter-second);
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--active);
  }
  &.active {
    background-color: var(--inter-second);
    color: #fff;
    &:hover {
      background-color: #605DFA;
    }
  }
  &.disabled {
    background: rgba(160, 161, 253, 0.15);
    color: var(--background-block);
  }
}


.block--title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

@keyframes toggletrack {
  0% {
    transform: translateY(500px);
  }
  60% {
    transform: translateY(100px);
  }
  100% {
    position: relative;
    transform: translateY(0);
    height: auto;
    margin-bottom: 60px;
  ;
  }
}
ymaps {
  text-overflow: clip!important;
  white-space: break-spaces!important;
  line-height: 115%;
  font-family: var(--font-family);
  letter-spacing: -0.13px;
  font-weight: 500;
  font-size: 13px;
  color: #d7d7d7;
  .ymaps-2-1-79-search__suggest-item {
    padding: 10px 0;
  }
  &[data-index] {
    &:not(:last-child) {
      margin-bottom: 2px;
    }
    .ymaps-2-1-79-search__suggest-highlight {
      color: var(--text-color);
      font-weight: 500;
    }

  }
}
.ymaps-2-1-79-search__suggest.ymaps-2-1-79-search__suggest {
  border-radius: 10px;
  padding: 10px 8px;
}


