html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  color: var(--text-color);
  min-width: 320px;
  min-height: 100%;
  font-family: var(--font-family, sans-serif);
  font-size: 13px;
  letter-spacing: -0.13px;
  font-weight: 500;
  line-height: 23px;
  background-color: var(--background-color);
  @include tablet {
    font-size: 16px;
    line-height: 19px;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-size: 50px;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--background-block);
  @include tablet {
    font-size: 40px;
  }
  @include mobile {
    font-size: 30px;
    font-weight: 700;
  }
}
.subtitle {
  font-size: 22px;
  line-height: 120%;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--background-block);
  @include tablet {
    font-size: 20px;
  }
  @include mobile {
    font-size: 18px;
  }
}


button {
  font-family: var(--font-family, sans-serif);
}
.list {
  li {
    position: relative;
    display: flex;
    &:before {
      content: '';
      width: 8px;
      flex: 0 0 8px;
      height: 11px;
      margin-right: 20px;
      margin-top: 5px;
      background-image: url('/wp-content/themes/blackriver/front/app/img/drop.svg');
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:not(:last-child) {
      margin-bottom: 31px;
      @include small-tablet {
        margin-bottom: 25px;
      }
    }
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}
button,input[type=submit] {
  font-family: var(--font-family);
  border: none;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  background-color: var(--inter-color);
  border: none;
  color: var(--inter-second);
  padding: 6px 15px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
  &:hover {
    background-color: var(--active);
    color: var(--inter-second)
  }
  &:active {
    color: var(--light-color);
    background-color: var(--inter-second);
  }
  @include mobile {
    padding: 4px 10px;
  }

}
.tel {
  border-radius: 30px;
  background-color: var(--light-color);
  font-size: 16px;
  font-weight: 700;
  color: var(--tel);
  padding: 4px 10px;
  text-decoration: none;
}

a {
  color: var(--main-color);
  text-decoration: underline;
}
.more {
  position: relative;
  display: flex;
  color: var(--main-color);
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
  &:before {
    content: '';
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin-right: 9px;
    background-image: url("/wp-content/themes/blackriver/front/app/img/more.svg");
    background-repeat: no-repeat;
    background-size: contain;
    @include small-tablet {
      flex: 0 0 18px;
      height: 18px;
      margin-right: 3px;
    }
  }
}


.list {

}
.btn {

}
.input {

}
.title {

}
.subtitle {

}
