.header {
  padding: 18px 0 20px;
  position: relative;
  @include mobile {
    background-color: var(--light-color);
    padding-bottom: 13px;
    padding-top: 12px;
  }
  .container {
    display: flex;
    align-items: center;
  }
  &__logo {
    margin-top: 2px;
    @include mobile {
      margin-top: 3px;
    }
  }
  &__tel {
    margin-left: 40px;
    font-size: 17px;
    @include tablet {
      font-size: 15px;
    }
    @include small-tablet {
      z-index: 5;
    }
    @include mobile {
      background-color: rgba(160, 161, 253, 0.15);
    }
    @include mobilemini {
      margin-left: 15px;
    }
  }
  &__logo {
    @include small-tablet {
      z-index: 5;
    }
  }
  &__btn {
    font-size: 17px;
    font-family: var(--font-family);
    @include tablet {
      font-size: 15px;
    }
    @include small-tablet {
      z-index: 5;
      position: fixed;
      right: 16px;
      top: calc(100vh + 19px);
    }
    &.active {
      top: 26px;
    }
    @include mobile {
      position: initial;
      padding: 8px 12px;
      margin-top: -5px;
      font-size: 18px;
      color: var(--tel);
    }
  }
  &__menu {
    margin-left: auto;
    display: flex;
    @include small-tablet {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      margin: 0;
      z-index: 4;
      transform: translateY(-100vh);
      background-color: var(--light-color);
      padding: 86px 16px 40px;
      box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    @include mobile {
      flex-direction: column;
      align-items: flex-end;
      transition: .3s;
    }
    &.active {
      transform: translateY(0);
    }
    .menu__list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      @include small-tablet {
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
      }
      .list__item {
        margin-right: 10px;
        @include small-tablet {
          margin-right: 0;
          margin-bottom: 25px;
        }
        .item__link {
          padding: 6px 15px;
          text-decoration: none;
          border-radius: 30px;
          font-size: 17px;
          font-weight: 600;
          color: var(--background-block);
          @include tablet {
            font-size: 15px;
          }
          @include small-tablet {
            padding: 6px 15px;
            background-color: var(--inter-color);
            color: var(--tel);
            font-size: 18px;
          }
          &:hover {
            background-color: var(--inter-color);
            color: var(--background-block);
            @include small-tablet {
              background-color: var(--tel);
              color: var(--light-color);
            }
          }
          &.calc {
          }
        }
        &:first-child .item__link {
          @include small-tablet {
            background-color: var(--inter-color);
            color: var(--background-block);
            @include small-tablet {
              background-color: var(--tel);
              color: var(--light-color);
            }
          }
        }
      }
    }
  }
  &__burger {
    display: none;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    color: var(--light-color);
    border: none;
    background: transparent;
    position: relative;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    margin-right: 151px;
    z-index: 5;
    @include small-tablet {
      display: flex;
    }
    @include mobile {
      margin-right: 0;
      padding: 0;
    }
    &:focus {
      outline:  none;
    }
    .burger__icon {
      width: 24px;
      height: 24px;
      display: block;
      margin-bottom: 5px;
      &:before,
      &:after,
      & span {
        background: var(--background-block);
        content: "";
        display: block;
        height: 2px;
        border-radius: 3px;
        margin: 5px 0;
        transition: 0.5s;
      }
    }
    &.active .burger__icon:before {
      transform: translateY(3px) rotate(135deg);
    }
    &.active .burger__icon:after {
      transform: translateY(-11px) rotate(-135deg);
    }
    &.active .burger__icon {
      margin-bottom: -3px;
      span {
        transform: scale(0);
      }
    }
  }
}
