.flex {
  display: flex;
}
.flex-a-center {
  display: flex;
  align-items: center;
}
.flex-a-end {
  display: flex;
  align-items: flex-end;
}
.flex-j-center {
  display: flex;
  justify-content: center;
}
.flex-j-sb {
  display: flex;
  justify-content: space-between;
}
.flex-j-sa {
  display: flex;
  justify-content: space-around;
}
.grid {
  display: grid;
}
