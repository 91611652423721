.footer {
  background-color: var(--background-block);
  padding: 40px 0;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  z-index: 2;
  @include small-tablet {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  @include mobile {
    padding: 30px 0;
  }
  &.hide {
    @include mobile {
      display: none;
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 308px 140px 240px;
    grid-template-rows: 27px 43px;
    justify-content: space-between;
    grid-row-gap: 11px;
    @include small-tablet {
      display: grid;
      grid-template-columns: 250px 308px;
      grid-template-rows: 28px 41px 32px;
    }
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0;
    }
  }
  .container {

  }
  &__logo {
    grid-area: 1 / 1 / 2 / 2;
    @include small-tablet {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
  &__info {
    grid-area: 2 / 1 / 3 / 2;
    font-size: 12px;
    color: var(--text);
    @include small-tablet {
      grid-area: 3 / 1 / 4 / 3;
      display: flex;
      justify-content: space-between;
    }
    @include mobile {
      order: 2;
      flex-direction: column;
      align-items: center;
      margin-top: 47px;
    }
    p {
      margin: 0;
    }
    .info__conf {
      margin-top: 11px;
      color: var(--text);
      text-decoration: none;
    }
  }
  &__social {
    grid-area: 1 / 2 / 3 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--light-color);
    @include small-tablet {
      grid-area: 1 / 2 / 3 / 3;
      align-items: flex-end;
    }
    @include mobile {
      order: 1;
      align-items: center;
      margin-top: 30px;
    }

    .social__list {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 18px 0 0 0;
      @include small-tablet {
        margin-top: 9px;
      }
      @include small-tablet {
        margin-top: 16px;
      }
      .social__item {
        &:not(:last-child) {
          margin-right: 10px;
        }
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  &__tel {
    grid-area: 1 / 3 / 3 / 4;
    display: flex;
    align-items: center;
    justify-content: center;
    @include small-tablet {
      grid-area: 2 / 1 / 3 / 2;
      justify-content: flex-start;
    }
    @include mobile {
      margin-top: 30px;
    }
    .tel {
      font-size: 26px;
      font-weight: 700;
      border-right: 40px;
      padding: 4px 16px;
      color: var(--light-color);
      background-color: var(--tel);
    }
  }
}
