main.map {
  @include tablet {
    margin-bottom: 60px;
  }
  #map {
    border-radius: 20px;
  }
  .container {
    position: relative;
    height: 602px;
    overflow: hidden;
  }

  .warnings, .first, .flats-info, .static {
    position: absolute;
    left: 20px;
    bottom: 55px;
    top: 115px;
    transition: .4s;
    &.hide {
      left: -100vw;
    }
    .tip {
      padding: 10px 12px;
      border-radius: 15px;
      color: #6667AB;
      background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
      &__text {
        display: flex;
        width: 100%;
      }
      &__icon {
        margin-right: 10px;
        flex: 0 0 24px;
        height: 24px;
      }
      &__close {
        margin-left: 10px;
      }
    }
  }
  .first {
    width: 308px;
    top: 155px;
    &.hide {
      display: none;
    }
    .tip__text {
      flex-wrap: wrap;
    }
    .text__in {
      flex: 0 0 220px
    }
    .fake__btn {
      flex: 0 0 100%;
      span {
        font-weight: 600;
        font-size: 13px;
        border-radius: 33px;
        padding: 5px 15px;
        background-color: #6567FE;
        color: #fff;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 134px;
        margin-left: auto;
        margin-top: 15px;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
  .warnings {
    width: 279px;
    top: 270px;
  }
  .flats-info {
    width: 308px;
    top: inherit;
    display: grid;
    gap: 10px;
    justify-content: end;
  }
  .static {
    position: absolute;
    left: 745px;
    top: inherit;
    bottom: 70px;
    &.active {
      display: none;
    }
    &__tip {
      width: 308px;
      height: 92px;
      position: relative;
      line-height: 132%;
      background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
      &:before {
        content: '';
        width: 0;
        height: 0;
        bottom: -20px;
        position: absolute;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 24px solid #EFF1FE;
        border-radius: 30px;
        right: 60px;
      }
    }
  }
  .panel {
    position: absolute;
    padding: 10px;
    border-radius: 15px;
    background-color: #FCFCFC;
    left: 20px;
    top: 10px;
    transition: .4s;
    &.hide {
      top: -100vh;
    }
    &__tip {
      position: absolute;
      width: 328px;
      height: 90px;
      display: none;
      &.active {
        display: block;
      }
      &.tip--had {
        line-height: 132%;
        position: absolute;
        top: 110px;
        left: 540px;
        bottom: inherit;
        .tip {
          background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
          &:before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-bottom: 12px solid transparent;
            border-right: 24px solid #EFF1FE;
            border-top: 12px solid transparent;
            border-radius: 30px;
            top: 32px;
            left: -20px;
          }
          svg {
            flex: 0 0 24px;
            margin-right: 10px;
          }
          &__text {
            display: flex;
            width: 100%;
          }
          &__close {
            margin-left: 10px;
          }
        }
      }
      &.tip--want {
        line-height: 132%;
        position: absolute;
        top: 155px;
        left: 352px;
        bottom: inherit;
        .tip {
          background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
          &:before {
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-right: 12px solid transparent;
            border-bottom: 24px solid #EFF1FE;
            border-left: 12px solid transparent;
            border-radius: 30px;
            top: -20px;
            left: 80px;
            margin: 0 auto;
          }
          svg {
            flex: 0 0 24px;
            margin-right: 10px;
          }
          &__text {
            display: flex;
            width: 100%;
          }
          &__close {
            margin-left: 10px;
          }
        }
      }
    }
    &__toggle {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
    }
    .btn-map {
      font-weight: 600;
      font-size: 13px;
      border-radius: 33px;
      padding: 5px 15px;
      background-color: var(--inter-color);
      color: var(--inter-second);
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      svg path {
        fill: var(--inter-second);
      }
      &.active {
        color: var(--light-color);
        background-color: var(--inter-second);
        svg path {
          fill: var(--light-color);
        }
      }

      svg {
        margin-right: 10px;
      }
    }
    &__want {
      margin-right: 10px;
    }
    &__save {
      margin-left: auto;
      box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
      border-radius: 23px;
      background-color: #ffffff;
      color: #581DFF;
      font-size: 13px;
      border: none;
      cursor: pointer;
      padding: 6px 10px;
      display: none;
      &.active {
        display: block;
        color: #fff;
        background-color: #581DFF;
      }
    }
    &__form {
      .form {
        &__want {
          display: none;
          //width: 578px;
          flex-wrap: wrap;
          &.active {
            display: flex;
          }
          align-items: center;
          .want {
            &__text {
              flex: 0 0 190px;
              margin-bottom: 6px;
              &.second {
                flex: 1 0 270px;
              }
            }
            &__toggle {
              flex: 0 0 170px;
              margin-right: 16px;
              .toggle__list {
                display: flex;
                align-items: center;
                .list__item {
                  flex: 0 0 40px;
                  height: 34px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #fff;
                  font-size: 16px;
                  color: #6667AB;
                  border-radius: 10px;
                  font-weight: 600;
                  cursor: pointer;
                  border: 1px solid var(--15, rgba(160, 161, 253, 0.15));
                  &:not(:last-child) {
                    margin-right: 5px;
                  }
                  &.active {
                    color: #fff;
                    background-color: #6667ab;
                  }
                }
              }
            }
            &__area {
              flex: 0 0 140px;
              border-radius: 10px;
              border: 1px solid rgba(160, 161, 253, 0.15);
              background: #FFF;
              font-size: 13px;
              font-weight: 500;
              color: #25282C;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-right: 16px;
              height: 34px;
              padding: 0 10px;
              &.active {
                outline: 1px solid #6667AB;
              }
              .area {
                &__value {
                  flex: 0 0 60px;
                  width: 60px;
                  text-align: center;
                  border: none;
                  outline: none;
                  &::-webkit-input-placeholder {color: rgba(99, 105, 112, 0.2);}
                  &:-moz-placeholder           {color: rgba(99, 105, 112, 0.2);}
                  &::-moz-placeholder          {color: rgba(99, 105, 112, 0.2);}
                  &:-ms-input-placeholder      {color: rgba(99, 105, 112, 0.2);}

                }
              }
            }
            &__region {
              flex: 0 0 143px;
              display: flex;
              padding: 6px 10px;
              align-items: center;
              border-radius: 23px;
              background: rgba(160, 161, 253, 0.15);
              font-size: 13px;
              font-weight: 600;
              color: #581DFF;
              cursor: pointer;
              svg {
                margin-right: 10px;
              }
              &:hover {
                background-color: var(--active);
              }
              &.active {
                color: var(--light-color);
                background-color: var(--inter-second);
                svg path {
                  fill: var(--light-color);
                }
              }
            }
          }
        }
        &__had {
          align-items: center;
          flex-wrap: wrap;
          display: none;
          width: 479px;
          &.active {
            display: flex;
            gap: 0 10px;
            .had__input {
              border-radius: 10px;
              border: 1px solid rgba(160, 161, 253, 0.15);
              background: #FFF;
              height: 38px;
              padding: 10px;
              font-size: 15px;
              &::-webkit-input-placeholder {font-size: 13px;}
              &:-moz-placeholder           {font-size: 13px;}
              &::-moz-placeholder          {font-size: 13px;}
              &:-ms-input-placeholder      {font-size: 13px;}
              &:focus {
                outline: 1px solid #6667AB;

              }
            }
            .input--stash, .input--monthly {
              flex: 0 0 224px;
              padding-left: 35px;
              margin-bottom: 10px;
              width: 224px;
              background: url(/wp-content/themes/vsedoma/front/app/img/money.svg) no-repeat 10px center/15px
            }
            .input--monthly {
              background-image: url("/wp-content/themes/vsedoma/front/app/img/month.svg");
            }
            .had__text {
              flex: 0 0 100%;
              color: #343c45;
              margin-bottom: 6px;
              &.text--column {
                flex: 0 0 224px;
                &.second {
                  //padding-left: 37px;
                }
              }
              &.text--small {
                flex: 0 0 40px;
              }

            }
            .had__room {
              flex: 0 0 74px;
              border-radius: 10px;
              border: 1px solid rgba(160, 161, 253, 0.15);
              background: #FFF;
              padding-left: 5px;
              height: 38px;
              margin-bottom: 10px;
            }
            .had__area {
              flex: 0 0 224px;
              width: 94px;
              background: #FFF;
              padding: 5px 10px;
              display: flex;
              justify-content: space-between;
              border-radius: 10px;
              border: 1px solid var(--30, rgba(147, 148, 226, 0.30));
              margin-bottom: 10px;
              &.active {
                border: 1px solid #6667AB;
              }
              .input--area {
                outline: none;
                width: 60px;
                border: none;
                &::-webkit-input-placeholder {color: rgba(99, 105, 112, 0.2);}
                &:-moz-placeholder           {color: rgba(99, 105, 112, 0.2);}
                &::-moz-placeholder          {color: rgba(99, 105, 112, 0.2);}
                &:-ms-input-placeholder      {color: rgba(99, 105, 112, 0.2);}
                &:focus {
                  outline: none;
                }
              }
            }
            .input--address {
              flex: 0 0 409px;
              border-radius: 10px;
              background: #FFF;
              padding: 5px 15px;
              margin-bottom: 6px;
            }
          }
          .had__toggle {
            flex: 0 0 224px;
            margin-bottom: 10px;
            .toggle__list {
              display: flex;
              align-items: center;
              .list__item {
                flex: 0 0 40px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                font-size: 16px;
                color: #6667AB;
                border-radius: 10px;
                font-weight: 600;
                cursor: pointer;
                border: 1px solid var(--15, rgba(160, 161, 253, 0.15));
                &:not(:last-child) {
                  margin-right: 5px;
                }
                &.active {
                  color: #fff;
                  background-color: #6667ab;
                }
              }
            }
          }
        }
      }
    }
  }
  .control {
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
  }
  .info {
    position: absolute;
    height: 48px;
    bottom: 0;
    left: 16px;
    width: calc(100% - 32px);
    background: linear-gradient(0deg, rgba(147, 148, 226, 0.30) 0%, rgba(147, 148, 226, 0.30) 100%), #FFF;
    border-radius: 2px 2px 15px 15px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    align-items: center;
    padding: 5px 20px;
    &.active {
      background-color: #6667AB;
      .info__btn {
        background-color: #581DFF;
        color: #fff;
      }
      .info__flat .flat__item {
        &:before {
          background-image: url(/wp-content/themes/vsedoma/front/app/img/stash-active.svg);
        }
        &.month:before {
          background-image: url(/wp-content/themes/vsedoma/front/app/img/month-active.svg);
        }
        &.price:before {
          background-image: url(/wp-content/themes/vsedoma/front/app/img/home-active.svg);
        }
      }
      .info__warn {
        color: #A2A3D0;
      }
    }
    .info__btn {
      opacity: 0;
      color: #581DFF;
      border-radius: 23px;
      background-color: #ffffff;
      padding: 6px 10px;
      font-size: 13px;
      font-weight: 600;
    }
    &__flat {
      flex: 0 0 344px;
      display: flex;
      align-items: center;
      color: #A2A3D0;
      .flat__item {
        display: flex;
        align-items: center;
        position: relative;
        flex: 0 0 30%;
        &:before {
          content: '';
          flex: 0 0 18px;
          height: 18px;
          margin-right: 8px;
          background: url(/wp-content/themes/vsedoma/front/app/img/money.svg) no-repeat center/contain;
        }
        &.month:before {
          background-image: url(/wp-content/themes/vsedoma/front/app/img/month.svg);
        }
        &.price:before {
          background-image: url(/wp-content/themes/vsedoma/front/app/img/home.svg);
        }
      }
    }
    &__op {
      margin-right: 370px;
      color: #fff;
      padding: 6px 9px;
      border-radius: 15px;
      font-size: 15px;
      font-weight: 600;
      transform: translateY(0);
      flex: 0 0 270px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.hide {
        display: none;
      }
    }
    &__warn {
      display: flex;
      align-items: center;
      margin-right: 370px;
      color: #6667AB;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: -0.13px;
      transform: translateY(0);
      svg {
        margin-right: 10px;
      }
      &.hide {
        display: none;
      }
    }
  }
  .wrapper__info {
    position: absolute;
    right: 40px;
    width: 335px;
    height: 595px;
    bottom: -100vh;
    overflow: scroll;
    background-color: #fff;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    transition: .6s;
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &.active {
      bottom: -506px;
    }
    &.active--full {
      bottom: 0;
    }
    .offer__wrapper {
      display: none;
      position: relative;
      &.active {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 30px 0 0 0;
        height: 100%;
      }
      .offer__tap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        cursor: pointer;
        &.hide {
          display: none;
        }
      }
      .offer__controls {
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
      .offer__open {
        transition: .4s;
        height: 25px;
        &.active {
          transform: rotate(180deg);
        }
      }

        &.hide {
          @include mobile {
            display: none;
          }
        }
        .controls__count {
          font-size: 18px;
          font-weight: 600;
          color: var(--background-block);
          display: flex;
          align-items: center;
          .count__text {
            position: relative;
            display: flex;
            flex: 0 0 190px;

            &:before {
              content: '';
              flex: 0 0 18px;
              height: 18px;
              margin-top: 2px;
              margin-right: 10px;
              background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center / contain;
            }
          }
          .count__value {
            font-weight: 500;
            font-size: 16px;
            color: var(--accent);
            background-color: var(--background-color);
            padding: 2px 10px;
            border-radius: 30px;
            margin-left: 10px;
            width: fit-content;
          }

        }
        .btn--small {
          display: none;
          @include mobile {
            display: block;
          }
          @include mobilemini {
            display: none;
          }
        }
        .controls__option {
          border: 0;
          background-color: transparent;
          padding: 0;
          display: none;
          @include mobile {
            display: none;
          }
        }

      }
      .new__plate {
        display: none;
        margin: 0 26px 0 16px;
        background-color: var(--active);
        .plate__row {
          align-items: center;
          .plate__title:before {
            background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center / contain;
          }
          .row__btn {
            border-radius: 23px;
            background: rgba(160, 161, 253, 0.15);
            padding: 6px 10px;
            color: var(--background-block);
            font-size: 13px;
            font-weight: 600;
            border: none;
            &.active {
              background-color: var(--inter-second);
              color: var(--light-color);
            }
          }
        }
        .plate__form {
          .form__address {
            border-radius: 10px;

          }
        }
        &.active {
          @include mobile {
            display: block;
            flex: 0 0 auto;
          }
        }
      }
      .offer__list {
        display: grid;
        grid-template-columns: 100%;
        gap: 10px;
        padding: 0 15px;
        margin: 15px 0 0 0;
        list-style: none;
        width: 99%;
        overflow-y: scroll;
        height: 600px;
        align-content: start;
        &::-webkit-scrollbar {
          background-color: transparent;
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
        &::-webkit-scrollbar-thumb  {
          border-radius: 30px;
          height: 2px;
          background: var(--old-543534, rgba(0, 0, 0, 0.05));
        }
        &.hide {
          display: none;
        }
        .list__item {
          border-radius: 15px;
          height: 189px;
          background-color: var(--tel);
          position: relative;
          padding: 16px 16px 15px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
        }
        .item__title {
          display: flex;
          background-color: var(--light-color);
          padding: 11px 20px;
          border-radius: 45px;
          font-size: 25px;
          font-weight: 600;
          width: fit-content;
          margin: 0;
          sup {
            margin-left: 2px;
            font-size: 22px;
          }
          .item__area {
            margin-left: 10px;
            color: var(--accent);
          }
          .item__room {

          }
        }
        .item__addr, .item__price {
          margin-top: 6px;
          color: var(--light-color);
          font-size: 13px;
          font-weight: 500;
          letter-spacing: -0.13px;
          margin-left: 20px;
          max-width: 180px;
          line-height: 125%;
        }
        .item__btn {
          margin-top: auto;
          border-radius: 25px;
          padding: 8px 15px;
          background-color: #F1EDFF;
          color: var(--inter-second);
          font-size: 13px;
          font-weight: 600;
          width: fit-content;
          cursor: pointer;
          z-index: 3;
          @include mobile {
            padding: 5px 10px;
          }
        }
        .item__img {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
      .offer__error {
        display: none;
        padding: 15px 40px;
        @include mobile {
          padding: 15px 16px;
        }
        &.active {
          display: block;
        }
        .offer__tip {
          flex-wrap: wrap;
          background-color: #F5EEEF;
          color: var(--warn-color);
          width: 320px;
          .tip__title {
            margin: 0 0 10px 0;
            flex: 0 0 90%;
          }
          .tip__warn {
            flex: 0 0 18px;
            height: 18px;
            background: url("/wp-content/themes/vsedoma/front/app/img/warn.svg") no-repeat center / contain;
          }
        }
      }
    }
    .pass__wrapper {
      display: none;
      width: 100%;
      padding: 30px 15px;
      &.active {
        display: block;
      }
      .pass {
        &__control {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .control__title {
            font-size: 15px;
            font-weight: 600;
            margin: 0;
          }
          .control__back {
            font-weight: 500;
            padding: 6px 10px;
            display: flex;
            align-items: center;
            svg {
              margin-right: 8px;
            }
          }
        }
        &__my {
          margin-top: 10px;
          padding: 10px;
          background-color: var(--inter-color);
          border-radius: 15px;
          .my__row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &:not(:first-child) {
              margin-top: 8px;
              color: var(--tel);
            }
          }
          .row__title {
            padding-bottom: 12px;
            position: relative;
            margin: 0;
            &:before {
              content: '';
              width: 209px;
              height: 1px;
              position: absolute;
              bottom: 0;
              left: 0;
              background-color: rgba(102, 103, 171, .1);
            }

          }
          .row__btn {
            display: none;
            margin-bottom: 12px;
            @include mobile {
              display: block;
            }
          }
        }
        &__flat {
          margin-top: 10px;
          &.hide {
            display: none;
          }
          .flat__item {
            border-radius: 15px;
            height: 119px;
            background-color: var(--tel);
            position: relative;
            padding: 12px 18px 10px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .item__title {
              margin: 7px 0 0 0;
              display: flex;
              background-color: var(--light-color);
              padding: 8px 20px 5px;
              border-radius: 45px;
              font-size: 25px;
              font-weight: 600;
              width: fit-content;
              sup {
                margin-left: 2px;
                font-size: 18px;
              }
              .item__area {
                margin-left: 10px;
                color: var(--accent);
              }
              .item__room {

              }
            }
            .item__price {
              margin-top: 8px;
              color: var(--light-color);
              font-size: 13px;
              font-weight: 500;
              letter-spacing: -0.13px;
              margin-left: 20px;
            }
            .item__choose {
              border-radius: 27px;
              padding: 2px 8px 3px 8px;
              color: var(--light-color);
              background-color: var(--inter-color);
              width: fit-content;
            }
            .item__img {
              position: absolute;
              bottom: -15px;
              right: 0;
            }
          }
        }
        &__info {
          margin-top: 10px;
          border-radius: 15px;
          //border: 1px solid var(--inter-color);
          padding: 10px 0;
          background-color: var(--accent);
          &.active {
            width: 100%;
            height: 810px;
            left: 0;
            top: 0;
            margin-top: 10px;
            padding: 0 0 30px 0;
            background-color: var(--light-color);
            @include mobile {
              position: relative;
              margin-top: 10px;
              padding: 0;
            }
            .info__container {
              padding-top: 10px;
            }
          }
          .info {
            &__container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 10px;
              background-color: var(--accent);
              border-top-left-radius: 15px;
              border-top-right-radius: 15px;
            }
            &__title {
              margin: 0;
              position: relative;
              color: var(--light-color);
              display: flex;
              align-items: center;
              font-size: 15px;
              font-weight: 600;
              flex: 0 0 170px;
              @include mobilemini {
                flex: 0 0 155px;
              }
              &:before {
                content: '';
                margin-right: 10px;
                flex: 0 0 18px;
                height: 18px;
                background: url("/wp-content/themes/vsedoma/front/app/img/Document.svg") no-repeat center / contain;
              }
            }
            &__btn {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 0 0 108px;
              &:hover {
                background-color: #B3B3D5;
              }
              &.active {
                background-color: var(--light-color);
                color: var(--inter-second);
                .btn__icon {
                  background-color: var(--inter-color);
                  &:before, &:after {
                    transform: rotate(45deg);
                    top: 9px;
                  }
                  &:after {
                    transform: rotate(-45deg);
                  }
                }
              }
              .btn__icon {
                display: block;
                flex: 0 0 20px;
                height: 20px;
                border-radius: 16px;
                background-color: var(--inter-color);
                margin-left: 10px;
                margin-top: 2px;
                position: relative;
                transition: .3s;
                &:after, &:before {
                  content: '';
                  position: absolute;
                  width: 9px;
                  height: 2px;
                  border-radius: 1px;
                  background-color: var(--inter-second);
                  transform: rotate(45deg);
                  left: 3px;
                  top: 10px;
                  transition: .3s;
                }
                &:before {
                  transform: rotate(-45deg);
                  left: 9px;
                }
              }
            }
            &__wrapper {
              display: none;
              &.active {
                flex: 0 0 100%;
                height: 557px;
                display: block;
                border-bottom-right-radius: 15px;
                border-bottom-left-radius: 15px;
                background-color: var(--light-color);
                @include mobile {
                  height: auto;
                  padding-bottom: 10px;
                }
              }
              .info__text {
                padding: 20px 10px 10px;
                background-color: var(--accent);
                color: var(--light-color);
                display: flex;
                flex-wrap: wrap;
                border-bottom-right-radius: 20px;
                border-bottom-left-radius: 20px;
                font-size: 12px;
                position: relative;
                &:before {
                  content: '';
                  width: calc( 100% - 20px);
                  height: 1px;
                  background-color: var(--light-color);
                  position: absolute;
                  opacity: .3;
                  top: 10px;
                  right: 10px;

                }
                .info__column {
                  &:nth-child(2) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                  }
                  .info__price {
                    font-size: 15px;
                    font-weight: 600;
                  }
                }

              }
              .info__next {
                .info__title {
                  color: var(--accent);
                  margin: 12px 0 0 0;
                  display: block;
                  font-size: 15px;
                  font-weight: 600;
                  text-align: center;
                }
                .info__steps {
                  display: block;
                  width: 100%;
                  margin: 0 auto;
                  .steps__item {
                    display: flex;
                    padding: 12px 0;
                    &:not(:last-child) {
                      border-bottom: 1px solid rgba(147, 148, 226, 0.30);
                    }
                    .item__num {
                      flex: 0 0 43px;
                      padding: 4px 10px;
                      background-color: var(--tel);
                      color: var(--light-color);
                      font-size: 12px;
                      font-weight: 400;
                      border-radius: 50%;
                      text-align: center;
                      height: fit-content;
                      line-height: 140%;
                      .num__val {
                        font-size: 13px;
                        font-weight: 600;
                      }
                    }
                    .item__text {
                      margin-left: 8px;
                      font-size: 13px;
                      font-weight: 500;
                      color: var(--text-color);
                      flex: 0 0 calc(100% - 43px);
                    }
                  }
                }
                .info__img {
                  display: block;
                  margin: 0 auto 0 auto;
                }
                .info__text--final {
                  font-size: 15px;
                  font-weight: 600;
                  max-width: 343px;
                  text-align: center;
                  background-color: #FF8F51;
                  padding: 8px 10px;
                  border-radius: 10px;
                  color: var(--light-color);
                  margin: 0 auto;
                }
                .info__docs {
                  margin: 12px auto 0 auto;
                  border-radius: 15px;
                  border: 1px solid rgba(160, 161, 253, 0.15);
                  padding: 6px 10px;
                  display: flex;
                  font-size: 12px;
                  max-width: 343px;
                  color: var(--tel);
                  font-weight: 400;
                  .docs__check {
                    background: url("/wp-content/themes/vsedoma/front/app/img/checkdocs.svg") no-repeat center / contain;
                    flex: 0 0 30px;
                    margin-right: 8px;
                  }
                }
                .info__help {
                  color: var(--text-color);
                  margin: 12px auto 0 auto;
                  max-width: 343px;
                }
                .info__send {
                  margin: 12px auto 0 auto;
                  max-width: 343px;
                  display: flex;
                  justify-content: space-between;
                  .send__link {
                    padding: 0 10px;
                    flex: 0 0 47%;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 15px;
                    background-color: rgba(160, 161, 253, 0.15);
                    color: var(--inter-second);
                    font-size: 12px;
                    font-weight: 600;
                    text-decoration: none;
                    svg {
                      margin-right: 6px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .pass__form {
        margin-top: 30px;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 20px;
        .form__wrapper {
          margin-top: 10px;
          .form__tabs {
            display: grid;
            grid-template-columns: repeat(3, 1fr) ;
            gap: 5px;
            .form__tab {
              font-size: 12px;
              padding: 9px 10px;
              font-family: var(--font-family);
              color: var(--tel);
              display: flex;
              align-items: center;
              justify-content: center;
              svg {
                margin-right: 3px;
              }
              &.active {
                color: var(--light-color);
                background-color: var(--tel);
                svg path{
                  fill: var(--light-color);
                }
              }
            }
          }
          .form__input {
            margin-top: 10px;
            width: 100%;
            padding: 10px 15px;
            font-size: 18px;
            font-weight: 500;
            border-radius: 10px;
            border: 1px solid var(--old-543534, rgba(0, 0, 0, 0.05));
            font-family: Manrope;
            &:focus {
              outline: 1px solid var(--inter-second);
            }
          }
          ::-webkit-input-placeholder {color: rgba(37, 40, 44, .1);}
          :-moz-placeholder           {color: rgba(37, 40, 44, .1);}
          ::-moz-placeholder          {color: rgba(37, 40, 44, .1);}
          :-ms-input-placeholder      {color: rgba(37, 40, 44, .1);}
          .form__sub {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            .sub__text {
              font-size: 12px;
              font-weight: 400;
              line-height: 120%;
              flex: 0 0 100%;
              order: 1;
              margin-top: 14px;
              a {
                color: #4762AE;
                text-decoration: none;
              }
            }
            .form__submit {
              flex: 0 0 208px;
              padding: 16px;
              border-radius: 10px;
              background-color: var(--inter-second);
              color: var(--light-color);
              font-size: 16px;
              font-weight: 600;
              font-family: Manrope;
              cursor: pointer;
              flex: 0 0 100%;
            }
          }
        }
      }
    }
    .finish__wrapper {
      padding-top: 32px;
      padding-bottom: 30px;
      display: none;
      padding-right: 16px;
      padding-left: 16px;
      &.active {
        display: block;
      }
      .finish__link {
        background: var(--15, rgba(160, 161, 253, 0.15));
        border-radius: 10px;
        text-align: center;
        width: 100%;
        color: #6567FE;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        padding: 16px;
        margin-bottom: 15px;
        margin-top: 15px;
        text-decoration: none;
      }
      .finish__info {
        border-radius: 20px;
        background-color: var(--accent);
        background-image: url(/wp-content/themes/vsedoma/front/app/img/lead.svg);
        background-position: 100% 151%;
        background-repeat: no-repeat;
        padding: 16px 15px;
        height: 252px;
        .finish__title {
          font-size: 18px;
          font-weight: 600;
          color: var(--light-color);
          margin: 0 0 10px 0;
        }
        .finish__text {
          font-size: 12px;
          font-weight: 400;
          color: var(--light-color);
          max-width: 206px;
        }
      }
      .finish__pass {
        margin-top: 20px;
        background-color: rgba(160, 161, 253, 0.15);
        font-size: 16px;
        font-weight: 700;
        width: 100%;
        text-align: center;
        color: var(--active);
        text-decoration: none;
        padding: 16px;
        border-radius: 10px;
        display: block;
      }
      .finish__info--pass {
        display: block;
      }
    }
  }
  .ymaps-2-1-79-balloon {
    border-radius: 10px;
    overflow: hidden;
    ymaps {
      line-height: 130%;
      font-size: 12px;
      color: var(--inter-second);
    }
  }
}

ymaps.active .ymaps-2-1-79-map ymaps,ymaps.active .ymaps-2-1-79-map ymaps:after,ymaps.active .ymaps-2-1-79-map ymaps:before{
  cursor: pointer!important;
}
