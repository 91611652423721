@charset "UTF-8";
:root {
  --font-family: "Manrope", sans-serif;
  --content-width: 1400px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --text-color: #25282C;
  --background-color: #F6F7F8;
  --background-block: #343C45;
  --light-color: #fff;
  --inter-second: #581DFF;
  --inter-color: rgba(160, 161, 253, 0.15);
  --korp-color: #B0CB1F;
  --not-active: #A0A1FD;
  --active: rgba(147, 148, 226, 0.30);
  --accent: #FF8F51;
  --tel: #6667AB;
  --text: #DCDCDC;
  --warn-color: #EB5757;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  color: var(--text-color);
  min-width: 320px;
  min-height: 100%;
  font-family: var(--font-family, sans-serif);
  font-size: 13px;
  letter-spacing: -0.13px;
  font-weight: 500;
  line-height: 23px;
  background-color: var(--background-color);
}
@media (max-width: 1280px) {
  .page__body {
    font-size: 16px;
    line-height: 19px;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.title {
  font-size: 50px;
  font-weight: 600;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--background-block);
}
@media (max-width: 1280px) {
  .title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 30px;
    font-weight: 700;
  }
}

.subtitle {
  font-size: 22px;
  line-height: 120%;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--background-block);
}
@media (max-width: 1280px) {
  .subtitle {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .subtitle {
    font-size: 18px;
  }
}

button {
  font-family: var(--font-family, sans-serif);
}

.list li {
  position: relative;
  display: flex;
}
.list li:before {
  content: "";
  width: 8px;
  flex: 0 0 8px;
  height: 11px;
  margin-right: 20px;
  margin-top: 5px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/drop.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.list li:not(:last-child) {
  margin-bottom: 31px;
}
@media (max-width: 1023px) {
  .list li:not(:last-child) {
    margin-bottom: 25px;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

button, input[type=submit] {
  font-family: var(--font-family);
  border: none;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.btn {
  background-color: var(--inter-color);
  border: none;
  color: var(--inter-second);
  padding: 6px 15px;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  border-radius: 30px;
}
.btn:hover {
  background-color: var(--active);
  color: var(--inter-second);
}
.btn:active {
  color: var(--light-color);
  background-color: var(--inter-second);
}
@media (max-width: 767px) {
  .btn {
    padding: 4px 10px;
  }
}

.tel {
  border-radius: 30px;
  background-color: var(--light-color);
  font-size: 16px;
  font-weight: 700;
  color: var(--tel);
  padding: 4px 10px;
  text-decoration: none;
}

a {
  color: var(--main-color);
  text-decoration: underline;
}

.more {
  position: relative;
  display: flex;
  color: var(--main-color);
  text-decoration: underline;
  border: none;
  outline: none;
  background: transparent;
}
.more:before {
  content: "";
  flex: 0 0 28px;
  width: 28px;
  height: 28px;
  margin-right: 9px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/more.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 1023px) {
  .more:before {
    flex: 0 0 18px;
    height: 18px;
    margin-right: 3px;
  }
}

.flex {
  display: flex;
}

.flex-a-center {
  display: flex;
  align-items: center;
}

.flex-a-end {
  display: flex;
  align-items: flex-end;
}

.flex-j-center {
  display: flex;
  justify-content: center;
}

.flex-j-sb {
  display: flex;
  justify-content: space-between;
}

.flex-j-sa {
  display: flex;
  justify-content: space-around;
}

.grid {
  display: grid;
}

@media (max-width: 767px) {
  .site-container {
    position: relative;
  }
}

.main {
  display: flex;
  align-items: center;
  height: 100%;
}
@media (max-width: 767px) {
  .main {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .main.constructor {
    margin-bottom: auto;
  }
}
.main .container {
  width: 100%;
}
@media (max-width: 767px) {
  .main .container {
    padding-left: 0;
    padding-right: 0;
  }
}
.main .wrapper {
  display: grid;
  grid-template-columns: 1fr 343px;
  gap: 10px;
  width: 100%;
  height: 100%;
}
@media (max-width: 1280px) {
  .main .wrapper {
    margin-bottom: 40px;
  }
}
@media (max-width: 1023px) {
  .main .wrapper {
    grid-template-columns: 1fr 310px;
  }
}
@media (max-width: 767px) {
  .main .wrapper {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
.main .wrapper .about {
  grid-area: 1/1/3/2;
  border-radius: 20px;
  background: #E5E5FF;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 650px;
  overflow: hidden;
}
@media (max-width: 1280px) {
  .main .wrapper .about {
    height: 520px;
  }
}
@media (max-width: 1023px) {
  .main .wrapper .about {
    height: 650px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .about {
    padding: 18px 16px;
    height: auto;
    background-position: 92px 35px;
    background-size: 388px;
  }
}
.main .wrapper .about__img {
  position: absolute;
  right: 100px;
  bottom: -40px;
  width: 525px;
}
@media (max-width: 1280px) {
  .main .wrapper .about__img {
    right: -100px;
    width: 480px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .about__img {
    width: auto;
    top: 65px;
    right: -90px;
  }
}
.main .wrapper .about__title {
  max-width: 590px;
  margin: 0;
  z-index: 3;
}
.main .wrapper .about__text {
  font-size: 20px;
  font-weight: 600;
  margin-top: 6px;
  max-width: 329px;
  z-index: 3;
}
@media (max-width: 1280px) {
  .main .wrapper .about__text {
    font-size: 18px;
  }
}
@media (max-width: 1023px) {
  .main .wrapper .about__text {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .about__text {
    font-weight: 500;
    line-height: 130%;
    max-width: 278px;
  }
}
.main .wrapper .about__tradein {
  margin-top: 103px;
  border-radius: 20px;
  padding: 20px;
  background: rgba(255, 255, 255, 0.25);
  max-width: 383px;
  font-size: 14px;
  line-height: 120%;
  z-index: 3;
}
@media (max-width: 1280px) {
  .main .wrapper .about__tradein {
    font-size: 12px;
    margin-top: 53px;
  }
}
@media (max-width: 1023px) {
  .main .wrapper .about__tradein {
    margin-top: 126px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .about__tradein {
    max-width: 210px;
    margin-top: 30px;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
  }
}
.main .wrapper .about__tradein .tradein__title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 7px 0;
}
@media (max-width: 1280px) {
  .main .wrapper .about__tradein .tradein__title {
    font-size: 18px;
  }
}
.main .wrapper .about__deal {
  margin-top: 22px;
  padding: 20px;
  box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  background: var(--light-color);
  max-width: 455px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  z-index: 4;
}
@media (max-width: 1023px) {
  .main .wrapper .about__deal {
    padding: 12px 10px;
    font-size: 12px;
  }
}
.main .wrapper .about__deal.active {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
  height: 366px;
  max-width: 100%;
  transition: 0.1s;
}
@media (max-width: 767px) {
  .main .wrapper .about__deal.active {
    position: initial;
    width: 100%;
    height: auto;
  }
}
.main .wrapper .about__deal.active .deal__btn .btn__icon:after {
  transform: translate(3px) rotate(45deg);
  top: 9px;
}
.main .wrapper .about__deal.active .deal__btn .btn__icon:before {
  transform: translate(-3px) rotate(-45deg);
  top: 9px;
}
.main .wrapper .about__deal .deal__title {
  font-size: 20px;
  margin: 0;
}
@media (max-width: 1280px) {
  .main .wrapper .about__deal .deal__title {
    font-size: 18px;
  }
}
@media (max-width: 1023px) {
  .main .wrapper .about__deal .deal__title {
    font-size: 15px;
  }
}
.main .wrapper .about__deal .deal__toggle {
  margin-top: 6px;
  font-size: 14px;
  line-height: 130%;
}
@media (max-width: 1280px) {
  .main .wrapper .about__deal .deal__toggle {
    font-size: 12px;
  }
}
.main .wrapper .about__deal .deal__btn {
  height: 30px;
  padding: 6px 10px 7px 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 85px;
}
@media (max-width: 767px) {
  .main .wrapper .about__deal .deal__btn {
    padding: 5px 10px;
    font-size: 13px;
    flex: 0 0 auto;
  }
}
.main .wrapper .about__deal .deal__btn .btn__icon {
  display: block;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 16px;
  background-color: var(--inter-color);
  margin-left: 6px;
  margin-top: 2px;
  position: relative;
  transition: 0.3s;
}
@media (max-width: 767px) {
  .main .wrapper .about__deal .deal__btn .btn__icon {
    display: none;
  }
}
.main .wrapper .about__deal .deal__btn .btn__icon:after, .main .wrapper .about__deal .deal__btn .btn__icon:before {
  content: "";
  position: absolute;
  width: 9px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--inter-second);
  transform: rotate(45deg);
  left: 3px;
  top: 10px;
  transition: 0.3s;
}
.main .wrapper .about__deal .deal__btn .btn__icon:before {
  transform: rotate(-45deg);
  left: 9px;
}
.main .wrapper .about__deal .deal__toggle--full {
  display: none;
  font-size: 14px;
}
@media (max-width: 1280px) {
  .main .wrapper .about__deal .deal__toggle--full {
    font-size: 12px;
  }
}
.main .wrapper .about__deal .deal__toggle--full.active {
  display: block;
}
.main .wrapper .about__deal .deal__toggle--full ul {
  padding: 0 0 0 13px;
  margin: 5px 0;
}
.main .wrapper .calc {
  grid-area: 1/2/2/3;
  background: var(--tel);
  border-radius: 20px;
  padding: 20px 16px;
  box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  color: var(--light-color);
}
@media (max-width: 767px) {
  .main .wrapper .calc {
    max-width: 100%;
    margin: 16px 16px 0;
  }
}
.main .wrapper .calc__title {
  font-size: 20px;
  z-index: 3;
}
@media (max-width: 1280px) {
  .main .wrapper .calc__title {
    font-size: 18px;
    max-width: 245px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .calc__title {
    font-weight: 600;
    max-width: 280px;
  }
}
.main .wrapper .calc__img {
  position: absolute;
  bottom: 0;
  right: -10px;
}
@media (max-width: 767px) {
  .main .wrapper .calc__img {
    bottom: -40px;
  }
}
.main .wrapper .calc__text {
  width: 200px;
  font-size: 15px;
  z-index: 3;
}
@media (max-width: 1280px) {
  .main .wrapper .calc__text {
    font-size: 13px;
  }
}
.main .wrapper .calc__btn {
  margin-top: auto;
  background-color: var(--inter-second);
  width: 100%;
  color: var(--light-color);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  border-radius: 16px;
  text-decoration: none;
  font-size: 16px;
  z-index: 3;
}
@media (max-width: 767px) {
  .main .wrapper .calc__btn {
    margin-top: 27px;
  }
}
.main .wrapper .calc__btn:hover {
  background-color: #605DFA;
}
.main .wrapper .calc__btn:active {
  background-color: var(--inter-second);
}
.main .wrapper .passport {
  grid-area: 2/2/3/3;
  padding: 20px 16px;
  background: var(--light-color);
  border-radius: 20px;
  box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .main .wrapper .passport {
    margin: 16px 16px 38px 16px;
  }
}
.main .wrapper .passport__title {
  font-size: 22px;
  margin: 0;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .main .wrapper .passport__title {
    font-size: 18px;
  }
}
.main .wrapper .passport__text {
  margin-top: 10px;
  max-width: 208px;
  font-size: 13px;
  position: relative;
  z-index: 3;
}
@media (max-width: 1280px) {
  .main .wrapper .passport__text {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .passport__text {
    max-width: 100%;
  }
}
.main .wrapper .passport__img {
  position: absolute;
  right: 0px;
  top: 0px;
}
@media (max-width: 1280px) {
  .main .wrapper .passport__img {
    top: -46px;
  }
}
@media (max-width: 1023px) {
  .main .wrapper .passport__img {
    top: 0;
    right: -22px;
  }
}
@media (max-width: 767px) {
  .main .wrapper .passport__img {
    position: relative;
    margin-top: -80px;
    top: 0;
    right: 0;
    transform: translateX(-40px);
    width: 100%;
  }
}
.main .wrapper .passport__btn {
  margin-top: auto;
  width: 100%;
  border-radius: 20px;
  font-size: 16px;
  padding: 20px 16px;
  background-color: rgba(160, 161, 253, 0.15);
  color: var(--inter-second);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 3;
}
@media (max-width: 767px) {
  .main .wrapper .passport__btn {
    margin-top: -80px;
  }
}
.main .wrapper .passport__btn:hover {
  background: rgba(147, 148, 226, 0.3);
  color: #605DFA;
}
.main .wrapper .passport__btn:active {
  background-color: var(--tel);
  color: var(--light-color);
}
.main .constructor {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  margin-bottom: 20px;
}
@media (max-width: 1280px) {
  .main .constructor {
    font-size: 13px;
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .main .constructor {
    grid-template-columns: 100%;
  }
}
.main .constructor__tip--mobile {
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__tip--mobile {
    display: block;
    width: 100%;
    background-color: var(--light-color);
    padding: 19px 16px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    position: relative;
    z-index: 1;
  }
  .main .constructor__tip--mobile:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url("/wp-content/themes/vsedoma/front/app/img/main-hero.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    border-radius: 15px;
  }
  .main .constructor__tip--mobile.hide {
    display: none;
  }
}
.main .constructor__tip--mobile .tip__title {
  color: var(--background-block);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 36px */
  text-transform: uppercase;
  margin: 0;
  z-index: 1;
}
.main .constructor__tip--mobile .tip__info {
  font-size: 13px;
  font-weight: 500;
  color: var(--background-block);
  width: 216px;
  z-index: 1;
}
.main .constructor__tip--mobile .tip__text {
  margin-top: 180px;
  font-size: 13px;
  font-weight: 500;
  color: var(--background-block);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  background-color: var(--light-color);
  padding: 10px 12px;
  border-radius: 15px;
  z-index: 1;
}
.main .constructor__tip--mobile .tip__text .text__title {
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 600;
  color: var(--tel);
}
.main .constructor__need {
  display: flex;
  margin-top: 19px;
}
.main .constructor__need.warn .need__title {
  display: none;
}
.main .constructor__need.warn .need__warn {
  display: flex;
  align-items: center;
}
.main .constructor__need.warn .need__warn:before {
  content: "";
  flex: 0 0 18px;
  height: 18px;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' width='18' height='18' rx='9' fill='%23EB5757'/%3E%3Cpath d='M9.5 6.86328V9.39453' stroke='white' stroke-width='1.06875' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8.76851 3.90935L4.12913 11.9246C4.0549 12.0528 4.01575 12.1984 4.01563 12.3466C4.0155 12.4947 4.05441 12.6403 4.12843 12.7687C4.20246 12.8971 4.30899 13.0037 4.4373 13.0778C4.56561 13.152 4.71119 13.191 4.85938 13.191H14.1381C14.2863 13.191 14.4319 13.152 14.5602 13.0778C14.6885 13.0037 14.795 12.8971 14.8691 12.7687C14.9431 12.6403 14.982 12.4947 14.9819 12.3466C14.9818 12.1984 14.9426 12.0528 14.8684 11.9246L10.229 3.90935C10.1549 3.7813 10.0484 3.67499 9.92022 3.60109C9.79205 3.52718 9.6467 3.48828 9.49875 3.48828C9.3508 3.48828 9.20545 3.52718 9.07728 3.60109C8.94911 3.67499 8.84262 3.7813 8.76851 3.90935V3.90935Z' stroke='white' stroke-width='1.06875' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M9.5 11.9258C9.84949 11.9258 10.1328 11.6425 10.1328 11.293C10.1328 10.9435 9.84949 10.6602 9.5 10.6602C9.15051 10.6602 8.86719 10.9435 8.86719 11.293C8.86719 11.6425 9.15051 11.9258 9.5 11.9258Z' fill='white'/%3E%3C/svg%3E%0A");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.main .constructor__need .need__title {
  margin: 0;
}
.main .constructor__need .need__warn {
  display: none;
  color: var(--warn-color);
  font-size: 12px;
  font-weight: 400;
  flex: 0 0 100%;
}
.main .constructor__control {
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__control {
    justify-content: space-between;
    align-items: center;
    padding: 0 16px 20px 16px;
    height: 60px;
    width: 100%;
    background-color: var(--active);
    position: absolute;
    left: 0;
    color: var(--tel);
  }
}
@media (max-width: 767px) {
  .main .constructor__control.flat {
    display: flex;
  }
}
@media (max-width: 767px) {
  .main .constructor__control.pass {
    display: flex;
  }
}
.main .constructor__control .control__btn.hide {
  display: none;
}
.main .constructor__column.column--params {
  padding: 20px 30px 0 30px;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--params {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--params {
    padding: 0 16px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--params.hide {
    transition-delay: 0.7s;
    display: none;
  }
}
.main .constructor__column.column--params .column__title {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  margin: 10px 0 0 0;
}
@media (max-width: 767px) {
  .main .constructor__column.column--params .column__title {
    font-size: 18px;
    font-weight: 800;
    position: relative;
    display: none;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--params .column__title.active {
    display: block;
  }
}
.main .constructor__column.column--params .column__tip {
  color: var(--inter-second);
  border: none;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  padding: 2px 7px;
  background-color: var(--inter-color);
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  display: none;
  height: fit-content;
}
.main .constructor__column.column--params .column__tip.active {
  display: flex;
}
@media (max-width: 767px) {
  .main .constructor__column.column--params .column__tip.active {
    display: none;
  }
}
.main .constructor__column.column--params .constructor__tip {
  margin-top: 14px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--params .constructor__tip {
    display: none;
  }
}
.main .constructor__column.column--offer {
  box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
  background-color: var(--light-color);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer {
    height: 676px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer {
    transition: 0.4s;
    position: absolute;
    top: 42px;
    left: 0;
    height: calc(100vh - 100px);
    width: 100%;
    transform: translateY(200vh);
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats {
    transform: translateY(0vh);
    position: relative;
    height: 215px;
    margin-bottom: -20px;
    margin-top: -32px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list {
    height: 223px;
    display: flex;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item {
    flex: 0 0 197px;
    height: 188px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__title {
    padding: 4px 10px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__title .item__room {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__title .item__area {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__title .item__area sup {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__img {
    bottom: -17px;
    right: -16px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__btn {
    margin-top: auto;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__addr, .main .constructor__column.column--offer.flats .offer__wrapper .offer__list .list__item .item__price {
    margin-left: 0;
  }
}
.main .constructor__column.column--offer.flats-active {
  align-items: flex-start;
  padding-top: 10px;
  height: auto;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active {
    margin-top: 0;
    animation: toggletrack 0.5s forwards;
  }
}
.main .constructor__column.column--offer.flats-active .offer__wrapper .offer__controls .controls__option {
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__controls .controls__option {
    display: block;
  }
}
.main .constructor__column.column--offer.flats-active .offer__wrapper .offer__tap {
  display: none;
}
.main .constructor__column.column--offer.flats-active .offer__wrapper .offer__controls .btn--small {
  display: none;
}
.main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list {
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list {
    height: 726px;
    display: grid;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item {
    flex: none;
    height: 188px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__title {
    padding: 10px 20px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__title .item__room {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__title .item__area {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__title .item__area sup {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__addr, .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__price {
    margin-left: 10px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__img {
    bottom: 0;
    right: 0;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer.flats-active .offer__wrapper .offer__list .list__item .item__btn {
    margin-top: auto;
  }
}
.main .constructor__column.column--offer .constructor__tip {
  flex: 0 0 343px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .constructor__tip {
    margin-top: 16px;
  }
}
@media (max-width: 330px) {
  .main .constructor__column.column--offer .constructor__tip {
    flex: 0 0 300px;
  }
}
.main .constructor__column.column--offer .column__loading {
  display: none;
}
.main .constructor__column.column--offer .column__loading.active {
  display: block;
}
.main .constructor__column.column--offer .offer__tap {
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__tap {
    border: none;
    display: block;
    width: fit-content;
    position: absolute;
    width: 100%;
    height: 70px;
    background-color: transparent;
    left: 0;
    top: 0;
  }
}
.main .constructor__column.column--offer .offer__wrapper {
  display: none;
}
.main .constructor__column.column--offer .offer__wrapper.active {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 0 0;
  height: 100%;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper.active {
    height: calc(100vh - 113px);
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls {
  display: flex;
  justify-content: space-between;
  padding: 0 40px;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls {
    padding: 0 10px 0 16px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls.hide {
    display: none;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__count {
  font-size: 18px;
  font-weight: 600;
  color: var(--background-block);
  flex: 0 0 550px;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__count {
    flex: 0 0 240px;
    font-size: 15px;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__count .count__text {
  position: relative;
  display: flex;
  flex: 0 0 190px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__count .count__text {
    flex: 0 0 165px;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__count .count__text:before {
  content: "";
  flex: 0 0 18px;
  height: 18px;
  margin-right: 10px;
  background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center/contain;
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__count .count__value {
  font-weight: 500;
  font-size: 16px;
  color: var(--accent);
  background-color: var(--background-color);
  padding: 2px 10px;
  border-radius: 30px;
  margin-left: 10px;
  width: fit-content;
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls .btn--small {
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls .btn--small {
    display: block;
  }
}
@media (max-width: 330px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls .btn--small {
    display: none;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__option {
  border: 0;
  background-color: transparent;
  padding: 0;
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__controls .controls__option {
    display: none;
  }
}
.main .constructor__column.column--offer .offer__wrapper .new__plate {
  display: none;
  margin: 0 26px 0 16px;
  background-color: var(--active);
}
.main .constructor__column.column--offer .offer__wrapper .new__plate .plate__row {
  align-items: center;
}
.main .constructor__column.column--offer .offer__wrapper .new__plate .plate__row .plate__title:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center/contain;
}
.main .constructor__column.column--offer .offer__wrapper .new__plate .plate__row .row__btn {
  border-radius: 23px;
  background: rgba(160, 161, 253, 0.15);
  padding: 6px 10px;
  color: var(--background-block);
  font-size: 13px;
  font-weight: 600;
  border: none;
}
.main .constructor__column.column--offer .offer__wrapper .new__plate .plate__row .row__btn.active {
  background-color: var(--inter-second);
  color: var(--light-color);
}
.main .constructor__column.column--offer .offer__wrapper .new__plate .plate__form .form__address {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .new__plate.active {
    display: block;
    flex: 0 0 auto;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  padding: 0 30px;
  margin: 15px 0 0 0;
  list-style: none;
  width: 99%;
  overflow-y: scroll;
  height: 655px;
  align-content: start;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__list {
    padding: 0 16px;
    height: 604px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__list {
    height: 670px;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__list::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}
.main .constructor__column.column--offer .offer__wrapper .offer__list::-webkit-scrollbar-track {
  display: none;
}
.main .constructor__column.column--offer .offer__wrapper .offer__list::-webkit-scrollbar-thumb {
  border-radius: 30px;
  height: 2px;
  background: var(--old-543534, rgba(0, 0, 0, 0.05));
}
.main .constructor__column.column--offer .offer__wrapper .offer__list.hide {
  display: none;
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .list__item {
  border-radius: 15px;
  height: 189px;
  background-color: var(--tel);
  position: relative;
  padding: 16px 16px 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .item__title {
  display: flex;
  background-color: var(--light-color);
  padding: 11px 20px;
  border-radius: 45px;
  font-size: 35px;
  font-weight: 600;
  width: fit-content;
  margin: 0;
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .item__title sup {
  margin-left: 2px;
  font-size: 22px;
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .item__title .item__area {
  margin-left: 10px;
  color: var(--accent);
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .item__addr, .main .constructor__column.column--offer .offer__wrapper .offer__list .item__price {
  margin-top: 6px;
  color: var(--light-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.13px;
  margin-left: 20px;
  max-width: 330px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__list .item__addr, .main .constructor__column.column--offer .offer__wrapper .offer__list .item__price {
    max-width: 220px;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .item__btn {
  margin-top: 5px;
  border-radius: 25px;
  padding: 8px 15px;
  background-color: #F1EDFF;
  color: var(--inter-second);
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
  z-index: 3;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__list .item__btn {
    padding: 5px 10px;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__list .item__img {
  position: absolute;
  bottom: 0;
  right: 0;
}
.main .constructor__column.column--offer .offer__wrapper .offer__error {
  display: none;
  padding: 15px 40px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .offer__wrapper .offer__error {
    padding: 15px 16px;
  }
}
.main .constructor__column.column--offer .offer__wrapper .offer__error.active {
  display: block;
}
.main .constructor__column.column--offer .offer__wrapper .offer__error .offer__tip {
  flex-wrap: wrap;
  background-color: #F5EEEF;
  color: var(--warn-color);
  width: 320px;
}
.main .constructor__column.column--offer .offer__wrapper .offer__error .offer__tip .tip__title {
  margin: 0 0 10px 0;
  flex: 0 0 90%;
}
.main .constructor__column.column--offer .offer__wrapper .offer__error .offer__tip .tip__warn {
  flex: 0 0 18px;
  height: 18px;
  background: url("/wp-content/themes/vsedoma/front/app/img/warn.svg") no-repeat center/contain;
}
.main .constructor__column.column--offer .pass__wrapper {
  display: none;
  width: 100%;
  padding: 10px 30px;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .pass__wrapper {
    padding: 0 16px;
  }
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .pass__wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #FCFCFC;
  }
}
.main .constructor__column.column--offer .pass__wrapper.active {
  display: block;
}
.main .constructor__column.column--offer .pass__wrapper .pass__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main .constructor__column.column--offer .pass__wrapper .pass__control .control__title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.main .constructor__column.column--offer .pass__wrapper .pass__control .control__back {
  font-weight: 500;
  padding: 6px 10px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__control .control__back.hide {
  display: none;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__control .control__back {
    display: none;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__my {
  margin-top: 10px;
  padding: 10px;
  background-color: var(--inter-color);
  border-radius: 15px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__my .my__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main .constructor__column.column--offer .pass__wrapper .pass__my .my__row:not(:first-child) {
  margin-top: 8px;
  color: var(--tel);
}
.main .constructor__column.column--offer .pass__wrapper .pass__my .row__title {
  padding-bottom: 12px;
  position: relative;
  margin: 0;
}
.main .constructor__column.column--offer .pass__wrapper .pass__my .row__title:before {
  content: "";
  width: 209px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(102, 103, 171, 0.1);
}
.main .constructor__column.column--offer .pass__wrapper .pass__my .row__btn {
  display: none;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__my .row__btn {
    display: block;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat {
  margin-top: 10px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat.hide {
  display: none;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item {
  border-radius: 15px;
  height: 119px;
  background-color: var(--tel);
  position: relative;
  padding: 12px 18px 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item .item__title {
  margin: 7px 0 0 0;
  display: flex;
  background-color: var(--light-color);
  padding: 8px 20px 5px;
  border-radius: 45px;
  font-size: 25px;
  font-weight: 600;
  width: fit-content;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item .item__title sup {
  margin-left: 2px;
  font-size: 18px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item .item__title .item__area {
  margin-left: 10px;
  color: var(--accent);
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item .item__price {
  margin-top: 8px;
  color: var(--light-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.13px;
  margin-left: 20px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item .item__choose {
  border-radius: 27px;
  padding: 2px 8px 3px 8px;
  color: var(--light-color);
  background-color: var(--inter-color);
  width: fit-content;
}
.main .constructor__column.column--offer .pass__wrapper .pass__flat .flat__item .item__img {
  position: absolute;
  bottom: -15px;
  right: 0;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info {
  margin-top: 10px;
  border-radius: 15px;
  border: 1px solid var(--inter-color);
  padding: 10px 0;
  background-color: var(--accent);
}
.main .constructor__column.column--offer .pass__wrapper .pass__info.active {
  width: 100%;
  margin-top: 10px;
  height: 855px;
  left: 0;
  top: 0;
  padding: 0 0 30px 0;
  background-color: var(--light-color);
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__info.active {
    position: relative;
    margin-top: 10px;
    padding: 0;
    height: auto;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__info.active .info__container {
  padding-top: 10px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: var(--accent);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__title {
  margin: 0;
  position: relative;
  color: var(--light-color);
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  flex: 0 0 170px;
}
@media (max-width: 330px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__info .info__title {
    flex: 0 0 155px;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__title:before {
  content: "";
  margin-right: 10px;
  flex: 0 0 18px;
  height: 18px;
  background: url("/wp-content/themes/vsedoma/front/app/img/Document.svg") no-repeat center/contain;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 108px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn:hover {
  background-color: #B3B3D5;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn.active {
  background-color: var(--light-color);
  color: var(--inter-second);
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn.active .btn__icon {
  background-color: var(--inter-color);
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn.active .btn__icon:before, .main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn.active .btn__icon:after {
  transform: rotate(45deg);
  top: 9px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn.active .btn__icon:after {
  transform: rotate(-45deg);
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn .btn__icon {
  display: block;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 16px;
  background-color: var(--inter-color);
  margin-left: 10px;
  margin-top: 2px;
  position: relative;
  transition: 0.3s;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn .btn__icon:after, .main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn .btn__icon:before {
  content: "";
  position: absolute;
  width: 9px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--inter-second);
  transform: rotate(45deg);
  left: 3px;
  top: 10px;
  transition: 0.3s;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__btn .btn__icon:before {
  transform: rotate(-45deg);
  left: 9px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper {
  display: none;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper.active {
  flex: 0 0 100%;
  height: 557px;
  display: block;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: var(--light-color);
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper.active {
    height: auto;
    padding-bottom: 10px;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__text {
  padding: 20px 10px 10px;
  background-color: var(--accent);
  color: var(--light-color);
  display: flex;
  flex-wrap: wrap;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 12px;
  position: relative;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__text:before {
  content: "";
  width: calc(100% - 20px);
  height: 1px;
  background-color: var(--light-color);
  position: absolute;
  opacity: 0.3;
  top: 10px;
  right: 10px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__text .info__column:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__text .info__column .info__price {
  font-size: 15px;
  font-weight: 600;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next {
  padding: 0 12px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__title {
  color: var(--accent);
  margin: 12px 0 0 0;
  display: block;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps {
  display: block;
  width: 343px;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps {
    width: auto;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item {
  display: flex;
  padding: 12px 0;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item:not(:last-child) {
  border-bottom: 1px solid rgba(147, 148, 226, 0.3);
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item .item__num {
  flex: 0 0 43px;
  padding: 4px 10px;
  background-color: var(--tel);
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  border-radius: 50%;
  text-align: center;
  height: fit-content;
  line-height: 140%;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item .item__num .num__val {
  font-size: 13px;
  font-weight: 600;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item .item__text {
  margin-left: 8px;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color);
  flex: 0 0 calc(100% - 43px);
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__img {
  display: block;
  margin: 0 auto 0 auto;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__text--final {
  font-size: 15px;
  font-weight: 600;
  max-width: 343px;
  text-align: center;
  background-color: #FF8F51;
  padding: 8px 10px;
  border-radius: 10px;
  color: var(--light-color);
  margin: 0 auto;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__docs {
  margin: 12px auto 0 auto;
  border-radius: 15px;
  border: 1px solid rgba(160, 161, 253, 0.15);
  padding: 6px 10px;
  display: flex;
  font-size: 12px;
  max-width: 343px;
  color: var(--tel);
  font-weight: 400;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__docs .docs__check {
  background: url("/wp-content/themes/vsedoma/front/app/img/checkdocs.svg") no-repeat center/contain;
  flex: 0 0 30px;
  margin-right: 8px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__help {
  color: var(--text-color);
  margin: 12px auto 0 auto;
  max-width: 343px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__send {
  margin: 12px auto 0 auto;
  max-width: 343px;
  display: flex;
  justify-content: space-between;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__send .send__link {
  padding: 0 10px;
  flex: 0 0 47%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: rgba(160, 161, 253, 0.15);
  color: var(--inter-second);
  font-size: 13px;
  font-weight: 600;
  text-decoration: none;
}
.main .constructor__column.column--offer .pass__wrapper .pass__info .info__wrapper .info__next .info__send .send__link svg {
  margin-right: 6px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form {
  margin-top: 30px;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper {
  margin-top: 10px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab {
  font-size: 15px;
  padding: 9px 21px;
  font-family: var(--font-family);
  color: var(--tel);
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab {
    font-size: 13px;
    padding: 9px 10px;
  }
}
@media (max-width: 330px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab {
    padding: 8px 15px;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab svg {
  margin-right: 8px;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab.active {
  color: var(--light-color);
  background-color: var(--tel);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab.active svg path {
  fill: var(--light-color);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__input {
  margin-top: 10px;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid var(--old-543534, rgba(0, 0, 0, 0.05));
  font-family: Manrope;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__input:focus {
  outline: 1px solid var(--inter-second);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper ::-webkit-input-placeholder {
  color: rgba(37, 40, 44, 0.3);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper :-moz-placeholder {
  color: rgba(37, 40, 44, 0.3);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper ::-moz-placeholder {
  color: rgba(37, 40, 44, 0.3);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper :-ms-input-placeholder {
  color: rgba(37, 40, 44, 0.3);
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub {
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub .sub__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub .sub__text {
    flex: 0 0 100%;
    order: 1;
    margin-top: 14px;
  }
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub .sub__text a {
  color: #4762AE;
  text-decoration: none;
}
.main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub .form__submit {
  flex: 0 0 208px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--inter-second);
  color: var(--light-color);
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .main .constructor__column.column--offer .pass__wrapper .pass__form .form__wrapper .form__sub .form__submit {
    flex: 0 0 100%;
  }
}
.main .constructor__column.column--offer .finish__wrapper {
  padding: 32px 16px 30px 16px;
  display: none;
}
.main .constructor__column.column--offer .finish__wrapper.active {
  display: block;
}
.main .constructor__column.column--offer .finish__wrapper .finish__info {
  border-radius: 20px;
  background-color: var(--accent);
  background-image: url(/wp-content/themes/vsedoma/front/app/img/lead.svg);
  background-position: 100% 151%;
  background-repeat: no-repeat;
  padding: 16px 15px;
  height: 252px;
}
.main .constructor__column.column--offer .finish__wrapper .finish__info .finish__title {
  font-size: 18px;
  font-weight: 600;
  color: var(--light-color);
  margin: 0 0 10px 0;
}
.main .constructor__column.column--offer .finish__wrapper .finish__info .finish__text {
  font-size: 12px;
  font-weight: 400;
  color: var(--light-color);
  max-width: 206px;
}
.main .constructor__column.column--offer .finish__wrapper .finish__link {
  background: var(--15, rgba(160, 161, 253, 0.15));
  border-radius: 10px;
  text-align: center;
  width: 100%;
  color: #6567FE;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  padding: 16px;
  margin-bottom: 15px;
  justify-content: center;
  margin-top: 15px;
  text-decoration: none;
}
.main .constructor__column.column--offer .finish__wrapper .finish__pass {
  margin-top: 20px;
  background-color: rgba(160, 161, 253, 0.15);
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: var(--active);
  text-decoration: none;
  padding: 16px;
  border-radius: 10px;
  display: block;
}
.main .constructor__column.column--offer .finish__wrapper .finish__info--pass {
  display: block;
}
.main .constructor__column .column__money {
  margin-top: 18px;
  width: 100%;
  background-color: var(--inter-color);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 8px;
  border-radius: 16px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .main .constructor__column .column__money {
    margin-top: 8px;
  }
}
.main .constructor__column .column__money .money__title {
  font-size: 13px;
  font-weight: 600;
  color: var(--inter-second);
}
.main .constructor__column .column__money .money__text {
  font-size: 12px;
  font-weight: 400;
  color: var(--tel);
}
.main .constructor__form {
  border-radius: 15px;
  padding: 15px 20px;
  background-color: var(--inter-color);
  margin-top: 16px;
  height: fit-content;
  position: relative;
}
@media (max-width: 1280px) {
  .main .constructor__form {
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .main .constructor__form {
    margin-top: 20px;
    padding-bottom: 20px;
  }
}
.main .constructor__form.plate {
  background-color: var(--active);
  border: 1px solid var(--active);
}
.main .constructor__form.warn {
  background-color: rgba(235, 87, 87, 0.05);
  border: 1px solid var(--warn-color);
}
.main .constructor__form.warn .own__plate {
  border: 1px solid var(--warn-color);
}
.main .constructor__form.warn .own__plate .plate__info .info__text {
  color: var(--warn-color);
}
.main .constructor__own {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1280px) {
  .main .constructor__own {
    padding-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .main .constructor__own {
    padding-bottom: 0;
  }
}
.main .constructor__buy .new__plate.finish {
  background-color: var(--tel);
  color: var(--light-color);
}
.main .constructor__buy .new__plate.finish .plate__title:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/Iconly2.svg") no-repeat center/contain;
}
.main .constructor__buy .new__plate.finish .plate__info .info__finish .finish__float, .main .constructor__buy .new__plate.finish .plate__info .info__finish .finish__room, .main .constructor__buy .new__plate.finish .plate__info .info__finish .finish__area, .main .constructor__buy .new__plate.finish .plate__info .info__finish .finish__address, .main .constructor__buy .new__plate.finish .plate__info .info__finish .finish__price {
  color: var(--light-color);
}
.main .constructor__buy .new__plate.finish .plate__btn {
  background-color: var(--light-color);
}
.main .constructor__buy .new__plate.active {
  background-color: var(--inter-color);
}
.main .constructor__buy .new__plate.active .plate__title:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/Iconly.svg") no-repeat center/contain;
}
.main .constructor__buy .new__plate.active .info__finish .finish__float, .main .constructor__buy .new__plate.active .info__finish .finish__room, .main .constructor__buy .new__plate.active .info__finish .finish__area, .main .constructor__buy .new__plate.active .info__finish .finish__address, .main .constructor__buy .new__plate.active .info__finish .finish__price {
  color: var(--text-color);
}
.main .constructor__buy .new__plate .plate__title:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/Iconly.svg") no-repeat center/contain;
}
.main .own__money, .main .own__month, .main .new__money, .main .new__month {
  flex: 0 0 49%;
}
@media (max-width: 1280px) {
  .main .own__money, .main .own__month, .main .new__money, .main .new__month {
    flex: 0 0 100%;
  }
}
.main .own__money .money__title, .main .own__money .month__title, .main .own__month .money__title, .main .own__month .month__title, .main .new__money .money__title, .main .new__money .month__title, .main .new__month .money__title, .main .new__month .month__title {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.main .own__money .money__title:before, .main .own__money .month__title:before, .main .own__month .money__title:before, .main .own__month .month__title:before, .main .new__money .money__title:before, .main .new__money .month__title:before, .main .new__month .money__title:before, .main .new__month .month__title:before {
  content: "";
  flex: 0 0 18px;
  height: 18px;
  background: url("/wp-content/themes/vsedoma/front/app/img/money.svg") no-repeat center/contain;
  margin-right: 10px;
}
.main .own__money .month__title:before, .main .own__month .month__title:before, .main .new__money .month__title:before, .main .new__month .month__title:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/month.svg") no-repeat center/contain;
}
@media (max-width: 1280px) {
  .main .own__money, .main .new__money {
    order: -2;
  }
}
.main .own__input, .main .new__input {
  padding: 10px 15px;
  border-radius: 10px;
  background-color: var(--light-color);
  border: 1px solid var(--inter-color);
  margin-top: 6px;
  width: 100%;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}
.main .own__input:focus, .main .new__input:focus {
  outline: 1px solid var(--inter-second);
}
.main .own__capital, .main .new__capital {
  padding: 5px 10px;
  border-radius: 33px;
  background-color: var(--inter-color);
  margin-top: 10px;
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: center;
  position: relative;
  align-content: center;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .main .own__capital, .main .new__capital {
    order: -1;
    margin-bottom: 10px;
  }
}
.main .own__capital:before, .main .new__capital:before {
  content: "";
  height: 16px;
  background: url("/wp-content/themes/vsedoma/front/app/img/checkout.svg") no-repeat center/contain;
}
.main .own__capital.active, .main .new__capital.active {
  background-color: var(--tel);
  color: var(--light-color);
}
.main .own__capital.active:before, .main .new__capital.active:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/checkin.svg") no-repeat center/contain;
}
.main .own__capital .capital__title, .main .new__capital .capital__title {
  margin-left: 10px;
}
.main .own__capital .input--capital, .main .new__capital .input--capital {
  display: none;
}
.main .own__plate, .main .new__plate {
  flex: 0 0 100%;
  margin-top: 10px;
  border-radius: 15px;
  padding: 15px 20px;
  background-color: var(--light-color);
  border: 1px solid var(--tel);
  position: relative;
}
@media (max-width: 1280px) {
  .main .own__plate, .main .new__plate {
    padding: 10px 12px;
  }
}
.main .own__plate.warn, .main .new__plate.warn {
  border: 1px solid var(--warn-color);
}
.main .own__plate svg, .main .new__plate svg {
  position: absolute;
  right: -40%;
  bottom: -25%;
  display: none;
}
.main .own__plate svg.active, .main .new__plate svg.active {
  display: block;
}
.main .own__plate .plate__title, .main .new__plate .plate__title {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 70%;
  font-weight: 600;
  font-size: 15px;
}
@media (max-width: 1280px) {
  .main .own__plate .plate__title, .main .new__plate .plate__title {
    flex: 0 0 174px;
  }
}
@media (max-width: 330px) {
  .main .own__plate .plate__title, .main .new__plate .plate__title {
    flex: 0 0 155px;
    font-size: 13px;
  }
}
.main .own__plate .plate__title:before, .main .new__plate .plate__title:before {
  content: "";
  flex: 0 0 18px;
  height: 18px;
  background: url("/wp-content/themes/vsedoma/front/app/img/own.svg") no-repeat center/contain;
  margin-right: 10px;
}
.main .own__plate .plate__row, .main .new__plate .plate__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main .own__plate .plate__form, .main .new__plate .plate__form {
  display: none;
  margin-top: 12px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main .own__plate .plate__form.active, .main .new__plate .plate__form.active {
  display: flex;
}
.main .own__plate .plate__form .form__room, .main .own__plate .plate__form .form__area, .main .own__plate .plate__form .form__address, .main .new__plate .plate__form .form__room, .main .new__plate .plate__form .form__area, .main .new__plate .plate__form .form__address {
  background-color: var(--light-color);
  border: 1px solid var(--inter-color);
  flex: 0 0 48%;
  border-radius: 10px;
  padding: 5px 15px;
}
@media (max-width: 330px) {
  .main .own__plate .plate__form .form__room, .main .own__plate .plate__form .form__area, .main .own__plate .plate__form .form__address, .main .new__plate .plate__form .form__room, .main .new__plate .plate__form .form__area, .main .new__plate .plate__form .form__address {
    flex: 0 0 40%;
  }
}
.main .own__plate .plate__form .form__area, .main .new__plate .plate__form .form__area {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 330px) {
  .main .own__plate .plate__form .form__area, .main .new__plate .plate__form .form__area {
    flex: 0 0 40%;
  }
}
.main .own__plate .plate__form .form__area.active, .main .new__plate .plate__form .form__area.active {
  outline: 1px solid var(--inter-second);
}
.main .own__plate .plate__form .form__area .form__input, .main .new__plate .plate__form .form__area .form__input {
  border: none;
  flex: 0 0 60px;
  width: 60px;
}
.main .own__plate .plate__form .form__area .form__input:focus, .main .new__plate .plate__form .form__area .form__input:focus {
  outline: none;
}
.main .own__plate .plate__form .form__area .form__input.warn::placeholder, .main .new__plate .plate__form .form__area .form__input.warn::placeholder {
  color: var(--warn-color);
}
.main .own__plate .plate__form .form__area span, .main .new__plate .plate__form .form__area span {
  margin-right: 10px;
}
.main .own__plate .plate__form .form__area span:nth-child(2), .main .new__plate .plate__form .form__area span:nth-child(2) {
  margin-right: 10px;
}
.main .own__plate .plate__form .form__address, .main .new__plate .plate__form .form__address {
  flex: 0 0 100%;
  position: relative;
  border: none;
  padding: 0;
  border-radius: 10px;
  margin-top: 10px;
}
.main .own__plate .plate__form .form__address .form__input, .main .new__plate .plate__form .form__address .form__input {
  width: 100%;
  border: 1px solid var(--inter-color);
  border-radius: 10px;
  font-size: 16px;
  padding: 7.5px 15px;
  font-size: 13px;
}
.main .own__plate .plate__form .form__address .form__input:focus, .main .new__plate .plate__form .form__address .form__input:focus {
  outline: 1px solid var(--inter-second);
}
.main .own__plate .plate__form .form__address .form__input.warn, .main .new__plate .plate__form .form__address .form__input.warn {
  outline: 1px solid var(--warn-color);
}
.main .own__plate .plate__form .form__address .form__input.warn::placeholder, .main .new__plate .plate__form .form__address .form__input.warn::placeholder {
  color: var(--warn-color);
}
.main .own__plate .plate__info, .main .new__plate .plate__info {
  margin-top: 10px;
  flex: 0 0 100%;
  color: var(--tel);
  letter-spacing: -0.12px;
}
@media (max-width: 767px) {
  .main .own__plate .plate__info, .main .new__plate .plate__info {
    font-size: 12px;
  }
}
.main .own__plate .plate__info.hide .info__text, .main .new__plate .plate__info.hide .info__text {
  display: none;
}
.main .own__plate .plate__info.warn .info__text, .main .new__plate .plate__info.warn .info__text {
  color: var(--warn-color);
}
.main .own__plate .plate__info .info__finish, .main .new__plate .plate__info .info__finish {
  display: none;
}
.main .own__plate .plate__info.finish, .main .new__plate .plate__info.finish {
  display: block;
}
.main .own__plate .plate__info.finish .info__finish, .main .new__plate .plate__info.finish .info__finish {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.main .own__plate .plate__info.finish .info__finish .finish__area, .main .new__plate .plate__info.finish .info__finish .finish__area {
  flex: 0 0 50%;
  margin-left: 20px;
}
@media (max-width: 1280px) {
  .main .own__plate .plate__info.finish .info__finish .finish__area, .main .new__plate .plate__info.finish .info__finish .finish__area {
    flex: 0 0 30%;
  }
}
.main .own__plate .plate__info.finish .info__finish .finish__address, .main .new__plate .plate__info.finish .info__finish .finish__address {
  flex: 1 0 70%;
  margin-top: 7px;
}
@media (max-width: 1280px) {
  .main .own__plate .plate__info.finish .info__finish .finish__address, .main .new__plate .plate__info.finish .info__finish .finish__address {
    flex: 1 0 60%;
  }
}
.main .own__plate .plate__info.finish .info__finish .finish__room, .main .new__plate .plate__info.finish .info__finish .finish__room {
  margin-left: 20px;
}
.main .own__plate .plate__info.finish .info__finish .finish__price, .main .new__plate .plate__info.finish .info__finish .finish__price {
  flex: 0 0 25%;
  text-align: right;
  font-weight: 600;
  font-size: 15px;
  color: var(--text-color);
  margin-top: 7px;
}
@media (max-width: 1280px) {
  .main .own__plate .plate__info.finish .info__finish .finish__price, .main .new__plate .plate__info.finish .info__finish .finish__price {
    flex: 0 0 35%;
  }
}
.main .own__plate .plate__btn, .main .new__plate .plate__btn {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 6px 10px;
  background-color: var(--inter-color);
  color: var(--inter-second);
  border: none;
  cursor: pointer;
}
.main .own__plate .plate__btn:hover, .main .new__plate .plate__btn:hover {
  background-color: var(--active);
}
.main .own__plate .plate__btn.active, .main .new__plate .plate__btn.active {
  background-color: var(--inter-second);
  color: #fff;
}
.main .own__plate .plate__btn.active:hover, .main .new__plate .plate__btn.active:hover {
  background-color: #605DFA;
}
.main .own__plate .plate__btn.disabled, .main .new__plate .plate__btn.disabled {
  background: rgba(160, 161, 253, 0.15);
  color: var(--background-block);
}
.main .own__info, .main .new__info {
  margin-top: 17px;
  color: var(--tel);
}
.main .own__info.hide, .main .new__info.hide {
  opacity: 0;
}
@media (max-width: 767px) {
  .main .own__info.hide, .main .new__info.hide {
    height: 41px;
  }
}
.main .own__stash, .main .new__stash {
  display: none;
}
.main .own__stash.active, .main .new__stash.active {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 7px 12px 10px 12px;
  border-radius: 2px 2px 15px 15px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
  color: var(--light-color);
  background-color: var(--tel);
  outline: 1px solid var(--tel);
}
@media (max-width: 1023px) {
  .main .own__stash.active span:nth-of-type(2), .main .new__stash.active span:nth-of-type(2) {
    flex: 0 0 83px;
  }
}
@media (max-width: 767px) {
  .main .own__stash.active span:nth-of-type(2), .main .new__stash.active span:nth-of-type(2) {
    text-align: right;
    flex: 1 0 113px;
  }
}
.main .own__stash.warn, .main .new__stash.warn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 7px 12px 10px 12px;
  border-radius: 2px 2px 15px 15px;
  display: flex;
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
  color: var(--light-color);
  background-color: var(--warn-color);
  outline: 1px solid var(--warn-color);
}
.main .offer__late {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 16px;
  width: 218px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  transition: 0.6s;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--inter-second);
  color: var(--light-color);
  bottom: 60px;
}
.main .offer__late.hide {
  bottom: -100%;
}
@media (max-width: 1023px) {
  .main .offer__late.hide {
    bottom: -100%;
  }
}
.main .offer__late.active {
  bottom: 60px;
}

.tip {
  display: none;
  padding: 10px 12px;
  border-radius: 15px;
  background-color: var(--inter-color);
  font-weight: 500;
  font-size: 13px;
  color: var(--tel);
  letter-spacing: -0.13px;
}
.tip.active {
  display: flex;
}
.tip__close {
  flex: 0 0 20px;
  padding: 0;
  height: 20px;
  border: none;
  border-radius: 50%;
  background-color: var(--inter-color);
  background-image: url("/wp-content/themes/vsedoma/front/app/img/close.svg");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.form {
  margin-top: 80px;
}
.form .container {
  background: var(--background-block);
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 1023px) {
  .form .container {
    padding-top: 23px;
    padding-bottom: 32px;
  }
}
.form .container form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 959px;
  margin: 35px auto 0 auto;
  position: relative;
}
@media (max-width: 1023px) {
  .form .container form {
    padding: 0 41px;
  }
}
@media (max-width: 767px) {
  .form .container form {
    padding: 0;
    margin-top: 10px;
  }
}
.form .container form label {
  flex: 0 0 425px;
  width: 425px;
}
@media (max-width: 1023px) {
  .form .container form label {
    width: initial;
    flex: 0 0 45%;
  }
}
@media (max-width: 767px) {
  .form .container form label {
    flex: 0 0 100%;
    margin-top: 25px;
  }
}
.form .container form label .form__name, .form .container form label .form__tel {
  margin-top: 8px;
  border-radius: 6px;
  border: 1px solid #374D57;
  background-color: var(--background-color);
  padding: 14px 8px;
  color: #6B818A;
}
@media (max-width: 1023px) {
  .form .container form label .form__name, .form .container form label .form__tel {
    width: 100%;
  }
}
.form .container form label .wpcf7-not-valid-tip {
  font-size: 12px;
}
.form .container form .form__btn {
  margin: 35px auto 0 auto;
  padding: 10px 38px;
  font-size: 16px;
}
@media (max-width: 1023px) {
  .form .container form .form__btn {
    margin-top: 28px;
  }
}
.form .container form .wpcf7-spinner {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -40px;
  margin: 0 auto;
}
.form .container form .wpcf7-response-output {
  flex: 0 0 100%;
  border: none;
  text-align: center;
  margin: 20px 0 25px;
  font-size: 14px;
}
@media (max-width: 1023px) {
  .form .container form .wpcf7-response-output {
    margin-bottom: 0;
  }
}
.form__title {
  margin: 0;
  text-align: center;
}
.form__text {
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 130%;
}
@media (max-width: 1280px) {
  .form__text {
    font-size: 26px;
    line-height: 32px;
  }
}
@media (max-width: 767px) {
  .form__text {
    font-size: 20px;
    line-height: 24px;
  }
}
.form__message {
  margin-top: 35px;
  text-align: center;
}
@media (max-width: 1023px) {
  .form__message {
    margin-top: 24px;
  }
}
.form__social {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1023px) {
  .form__social {
    margin-top: 16px;
  }
}
.form__social .social__item:not(:last-child) {
  margin-right: 35px;
}

.btn--small {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 5px 10px;
  background-color: var(--inter-color);
  color: var(--inter-second);
  border: none;
  cursor: pointer;
}
.btn--small:hover {
  background-color: var(--active);
}
.btn--small.active {
  background-color: var(--inter-second);
  color: #fff;
}
.btn--small.active:hover {
  background-color: #605DFA;
}
.btn--small.disabled {
  background: rgba(160, 161, 253, 0.15);
  color: var(--background-block);
}

.btn--white {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 5px 10px;
  background-color: var(--light-color);
  color: var(--inter-second);
  border: none;
  cursor: pointer;
}
.btn--white:hover {
  background-color: var(--active);
}
.btn--white.active {
  background-color: var(--inter-second);
  color: #fff;
}
.btn--white.active:hover {
  background-color: #605DFA;
}
.btn--white.disabled {
  background: rgba(160, 161, 253, 0.15);
  color: var(--background-block);
}

.block--title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
}

@keyframes toggletrack {
  0% {
    transform: translateY(500px);
  }
  60% {
    transform: translateY(100px);
  }
  100% {
    position: relative;
    transform: translateY(0);
    height: auto;
    margin-bottom: 60px;
  }
}
ymaps {
  text-overflow: clip !important;
  white-space: break-spaces !important;
  line-height: 115%;
  font-family: var(--font-family);
  letter-spacing: -0.13px;
  font-weight: 500;
  font-size: 13px;
  color: #d7d7d7;
}
ymaps .ymaps-2-1-79-search__suggest-item {
  padding: 10px 0;
}
ymaps[data-index]:not(:last-child) {
  margin-bottom: 2px;
}
ymaps[data-index] .ymaps-2-1-79-search__suggest-highlight {
  color: var(--text-color);
  font-weight: 500;
}

.ymaps-2-1-79-search__suggest.ymaps-2-1-79-search__suggest {
  border-radius: 10px;
  padding: 10px 8px;
}

.header {
  padding: 18px 0 20px;
  position: relative;
}
@media (max-width: 767px) {
  .header {
    background-color: var(--light-color);
    padding-bottom: 13px;
    padding-top: 12px;
  }
}
.header .container {
  display: flex;
  align-items: center;
}
.header__logo {
  margin-top: 2px;
}
@media (max-width: 767px) {
  .header__logo {
    margin-top: 3px;
  }
}
.header__tel {
  margin-left: 40px;
  font-size: 17px;
}
@media (max-width: 1280px) {
  .header__tel {
    font-size: 15px;
  }
}
@media (max-width: 1023px) {
  .header__tel {
    z-index: 5;
  }
}
@media (max-width: 767px) {
  .header__tel {
    background-color: rgba(160, 161, 253, 0.15);
  }
}
@media (max-width: 330px) {
  .header__tel {
    margin-left: 15px;
  }
}
@media (max-width: 1023px) {
  .header__logo {
    z-index: 5;
  }
}
.header__btn {
  font-size: 17px;
  font-family: var(--font-family);
}
@media (max-width: 1280px) {
  .header__btn {
    font-size: 15px;
  }
}
@media (max-width: 1023px) {
  .header__btn {
    z-index: 5;
    position: fixed;
    right: 16px;
    top: calc(100vh + 19px);
  }
}
.header__btn.active {
  top: 26px;
}
@media (max-width: 767px) {
  .header__btn {
    position: initial;
    padding: 8px 12px;
    margin-top: -5px;
    font-size: 18px;
    color: var(--tel);
  }
}
.header__menu {
  margin-left: auto;
  display: flex;
}
@media (max-width: 1023px) {
  .header__menu {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 4;
    transform: translateY(-100vh);
    background-color: var(--light-color);
    padding: 86px 16px 40px;
    box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}
@media (max-width: 767px) {
  .header__menu {
    flex-direction: column;
    align-items: flex-end;
    transition: 0.3s;
  }
}
.header__menu.active {
  transform: translateY(0);
}
.header__menu .menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
@media (max-width: 1023px) {
  .header__menu .menu__list {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
}
.header__menu .menu__list .list__item {
  margin-right: 10px;
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item {
    margin-right: 0;
    margin-bottom: 25px;
  }
}
.header__menu .menu__list .list__item .item__link {
  padding: 6px 15px;
  text-decoration: none;
  border-radius: 30px;
  font-size: 17px;
  font-weight: 600;
  color: var(--background-block);
}
@media (max-width: 1280px) {
  .header__menu .menu__list .list__item .item__link {
    font-size: 15px;
  }
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item .item__link {
    padding: 6px 15px;
    background-color: var(--inter-color);
    color: var(--tel);
    font-size: 18px;
  }
}
.header__menu .menu__list .list__item .item__link:hover {
  background-color: var(--inter-color);
  color: var(--background-block);
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item .item__link:hover {
    background-color: var(--tel);
    color: var(--light-color);
  }
}
@media (max-width: 1023px) {
  .header__menu .menu__list .list__item:first-child .item__link {
    background-color: var(--inter-color);
    color: var(--background-block);
  }
}
@media (max-width: 1023px) and (max-width: 1023px) {
  .header__menu .menu__list .list__item:first-child .item__link {
    background-color: var(--tel);
    color: var(--light-color);
  }
}
.header__burger {
  display: none;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: var(--light-color);
  border: none;
  background: transparent;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: 151px;
  z-index: 5;
}
@media (max-width: 1023px) {
  .header__burger {
    display: flex;
  }
}
@media (max-width: 767px) {
  .header__burger {
    margin-right: 0;
    padding: 0;
  }
}
.header__burger:focus {
  outline: none;
}
.header__burger .burger__icon {
  width: 24px;
  height: 24px;
  display: block;
  margin-bottom: 5px;
}
.header__burger .burger__icon:before, .header__burger .burger__icon:after, .header__burger .burger__icon span {
  background: var(--background-block);
  content: "";
  display: block;
  height: 2px;
  border-radius: 3px;
  margin: 5px 0;
  transition: 0.5s;
}
.header__burger.active .burger__icon:before {
  transform: translateY(3px) rotate(135deg);
}
.header__burger.active .burger__icon:after {
  transform: translateY(-11px) rotate(-135deg);
}
.header__burger.active .burger__icon {
  margin-bottom: -3px;
}
.header__burger.active .burger__icon span {
  transform: scale(0);
}

.footer {
  background-color: var(--background-block);
  padding: 40px 0;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  z-index: 2;
}
@media (max-width: 1023px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 30px 0;
  }
}
@media (max-width: 767px) {
  .footer.hide {
    display: none;
  }
}
.footer__wrapper {
  display: grid;
  grid-template-columns: 308px 140px 240px;
  grid-template-rows: 27px 43px;
  justify-content: space-between;
  grid-row-gap: 11px;
}
@media (max-width: 1023px) {
  .footer__wrapper {
    display: grid;
    grid-template-columns: 250px 308px;
    grid-template-rows: 28px 41px 32px;
  }
}
@media (max-width: 767px) {
  .footer__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
}
.footer__logo {
  grid-area: 1/1/2/2;
}
@media (max-width: 1023px) {
  .footer__logo {
    grid-area: 1/1/2/2;
  }
}
.footer__info {
  grid-area: 2/1/3/2;
  font-size: 12px;
  color: var(--text);
}
@media (max-width: 1023px) {
  .footer__info {
    grid-area: 3/1/4/3;
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .footer__info {
    order: 2;
    flex-direction: column;
    align-items: center;
    margin-top: 47px;
  }
}
.footer__info p {
  margin: 0;
}
.footer__info .info__conf {
  margin-top: 11px;
  color: var(--text);
  text-decoration: none;
}
.footer__social {
  grid-area: 1/2/3/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--light-color);
}
@media (max-width: 1023px) {
  .footer__social {
    grid-area: 1/2/3/3;
    align-items: flex-end;
  }
}
@media (max-width: 767px) {
  .footer__social {
    order: 1;
    align-items: center;
    margin-top: 30px;
  }
}
.footer__social .social__list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 18px 0 0 0;
}
@media (max-width: 1023px) {
  .footer__social .social__list {
    margin-top: 9px;
  }
}
@media (max-width: 1023px) {
  .footer__social .social__list {
    margin-top: 16px;
  }
}
.footer__social .social__list .social__item:not(:last-child) {
  margin-right: 10px;
}
.footer__social .social__list .social__item svg {
  width: 40px;
  height: 40px;
}
.footer__tel {
  grid-area: 1/3/3/4;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1023px) {
  .footer__tel {
    grid-area: 2/1/3/2;
    justify-content: flex-start;
  }
}
@media (max-width: 767px) {
  .footer__tel {
    margin-top: 30px;
  }
}
.footer__tel .tel {
  font-size: 26px;
  font-weight: 700;
  border-right: 40px;
  padding: 4px 16px;
  color: var(--light-color);
  background-color: var(--tel);
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s all;
  z-index: 20;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 0;
}
@media (max-width: 1920px) {
  .popup {
    width: 570px;
  }
}
@media (max-width: 767px) {
  .popup {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
}
.popup.active {
  transform: translate(-50%, -50%);
  transition: 0.5s all;
}
.popup .container {
  border-radius: 6px;
  position: relative;
}
@media (max-width: 1920px) {
  .popup .container .form__title {
    font-size: 27px;
  }
}
@media (max-width: 1920px) {
  .popup .container form label {
    margin-bottom: 13px;
  }
}
.popup .container form label .form__name, .popup .container form label .form__tel {
  width: 425px;
}
@media (max-width: 767px) {
  .popup .container form label .form__name, .popup .container form label .form__tel {
    width: 100%;
  }
}
.popup__bg {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: 0.5s all;
}
.popup__bg.active {
  opacity: 1;
  pointer-events: all;
  transition: 0.5s all;
}
.popup .close-popup {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.popup .close-popup:after {
  display: inline-block;
  content: "×";
  font-size: 40px;
  color: #CDA130;
}
.popup .close-popup:hover:before, .popup .close-popup:hover:after {
  color: var(--main-color);
}

.main.inner .breadcrumbs {
  margin-top: 50px;
}
@media (max-width: 1280px) {
  .main.inner .breadcrumbs {
    margin-top: 35px;
  }
}
@media (max-width: 767px) {
  .main.inner .breadcrumbs {
    display: none;
    margin-top: 0;
  }
}
.main.inner .news {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 30px;
  margin-bottom: 75px;
}
@media (max-width: 1023px) {
  .main.inner .news {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .main.inner .news {
    grid-template-columns: 100%;
    gap: 30px 15px;
    margin-bottom: 50px;
  }
}
.main.inner .news__title {
  margin-bottom: 50px;
}
@media (max-width: 1023px) {
  .main.inner .news__title {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .main.inner .news__title {
    margin-top: 80px;
    grid-area: auto;
  }
}
.main.inner .news .post-thumbnail .attachment-post-thumbnail {
  border-radius: 8px;
}
.main.inner .news__item .item__photo {
  border-radius: 8px;
}
.main.inner .news__item .item__date {
  font-size: 14px;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .main.inner .news__item .item__date {
    font-size: 12px;
  }
}
.main.inner .news__item .item__title {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 120%;
  margin: 4px 0 0 0;
}
@media (max-width: 1280px) {
  .main.inner .news__item .item__title {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .main.inner .news__item .item__title {
    font-size: 16px;
  }
}
.main.inner .news__item .item__title .item__link {
  color: var(--light-color);
  text-decoration: none;
  width: 30px;
}
.main.inner .pagination .nav-links {
  display: flex;
  justify-content: center;
  margin-bottom: 47px;
}
@media (max-width: 1023px) {
  .main.inner .pagination .nav-links {
    margin-bottom: 40px;
  }
}
.main.inner .pagination .nav-links .page-numbers {
  border-radius: 3px;
  border: 1px solid var(--main-color);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-color);
  text-decoration: none;
}
.main.inner .pagination .nav-links .page-numbers:not(:last-child) {
  margin-right: 13px;
}
.main.inner .pagination .nav-links .page-numbers.current {
  justify-content: center;
  color: var(--background-color);
  background-color: var(--main-color);
}
.main.inner .pagination .nav-links .page-numbers.prev, .main.inner .pagination .nav-links .page-numbers.next {
  border: none;
  color: var(--main-color);
  font-weight: 800;
}
@media (max-width: 767px) {
  .main.inner .article__title {
    margin-top: 80px;
  }
}
.main.inner .article__date {
  font-size: 14px;
  margin-top: 8px;
}
@media (max-width: 1023px) {
  .main.inner .article__date {
    font-size: 12px;
  }
}
.main.inner .article .info__photo {
  border-radius: 8px;
  float: left;
  margin-right: 38px;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  .main.inner .article .info__photo {
    margin-right: 0;
  }
}
.main.inner .article .nav-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
}
@media (max-width: 767px) {
  .main.inner .article .nav-links {
    margin-bottom: 28px;
  }
}
.main.inner .article .nav-links .nav-previous, .main.inner .article .nav-links .nav-next {
  position: relative;
  display: flex;
}
.main.inner .article .nav-links .nav-previous:before, .main.inner .article .nav-links .nav-next:before {
  content: "";
  width: 19px;
  flex: 0 0 19px;
  height: 17px;
  margin-right: 20px;
  margin-top: 5px;
  background-image: url("/wp-content/themes/blackriver/front/app/img/arrow.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1023px) {
  .main.inner .article .nav-links .nav-previous:before, .main.inner .article .nav-links .nav-next:before {
    flex: 0 0 14px;
    height: 11px;
    margin-right: 10px;
  }
}
.main.inner .article .nav-links .nav-next {
  flex-direction: row-reverse;
}
.main.inner .article .nav-links .nav-next:before {
  margin-left: 20px;
  margin-right: 0;
  transform: rotate(180deg);
}
@media (max-width: 1023px) {
  .main.inner .article .nav-links .nav-next:before {
    margin-right: 0;
    margin-left: 10px;
  }
}
.main.inner .error-404.not-found .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 360px);
}
.main.inner .error-404.not-found .container .not-found__title {
  margin: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: var(--background-color);
  background-image: linear-gradient(90deg, #CDA130, #FEFEFE);
}

@media (max-width: 1280px) {
  main.map {
    margin-bottom: 60px;
  }
}
main.map #map {
  border-radius: 20px;
}
main.map .container {
  position: relative;
  height: 602px;
  overflow: hidden;
}
main.map .warnings, main.map .first, main.map .flats-info, main.map .static {
  position: absolute;
  left: 20px;
  bottom: 55px;
  top: 115px;
  transition: 0.4s;
}
main.map .warnings.hide, main.map .first.hide, main.map .flats-info.hide, main.map .static.hide {
  left: -100vw;
}
main.map .warnings .tip, main.map .first .tip, main.map .flats-info .tip, main.map .static .tip {
  padding: 10px 12px;
  border-radius: 15px;
  color: #6667AB;
  background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
}
main.map .warnings .tip__text, main.map .first .tip__text, main.map .flats-info .tip__text, main.map .static .tip__text {
  display: flex;
  width: 100%;
}
main.map .warnings .tip__icon, main.map .first .tip__icon, main.map .flats-info .tip__icon, main.map .static .tip__icon {
  margin-right: 10px;
  flex: 0 0 24px;
  height: 24px;
}
main.map .warnings .tip__close, main.map .first .tip__close, main.map .flats-info .tip__close, main.map .static .tip__close {
  margin-left: 10px;
}
main.map .first {
  width: 308px;
  top: 155px;
}
main.map .first.hide {
  display: none;
}
main.map .first .tip__text {
  flex-wrap: wrap;
}
main.map .first .text__in {
  flex: 0 0 220px;
}
main.map .first .fake__btn {
  flex: 0 0 100%;
}
main.map .first .fake__btn span {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 5px 15px;
  background-color: #6567FE;
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 134px;
  margin-left: auto;
  margin-top: 15px;
}
main.map .first .fake__btn span svg {
  margin-right: 10px;
}
main.map .warnings {
  width: 279px;
  top: 270px;
}
main.map .flats-info {
  width: 308px;
  top: inherit;
  display: grid;
  gap: 10px;
  justify-content: end;
}
main.map .static {
  position: absolute;
  left: 745px;
  top: inherit;
  bottom: 70px;
}
main.map .static.active {
  display: none;
}
main.map .static__tip {
  width: 308px;
  height: 92px;
  position: relative;
  line-height: 132%;
  background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
}
main.map .static__tip:before {
  content: "";
  width: 0;
  height: 0;
  bottom: -20px;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 24px solid #EFF1FE;
  border-radius: 30px;
  right: 60px;
}
main.map .panel {
  position: absolute;
  padding: 10px;
  border-radius: 15px;
  background-color: #FCFCFC;
  left: 20px;
  top: 10px;
  transition: 0.4s;
}
main.map .panel.hide {
  top: -100vh;
}
main.map .panel__tip {
  position: absolute;
  width: 328px;
  height: 90px;
  display: none;
}
main.map .panel__tip.active {
  display: block;
}
main.map .panel__tip.tip--had {
  line-height: 132%;
  position: absolute;
  top: 110px;
  left: 540px;
  bottom: inherit;
}
main.map .panel__tip.tip--had .tip {
  background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
}
main.map .panel__tip.tip--had .tip:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-bottom: 12px solid transparent;
  border-right: 24px solid #EFF1FE;
  border-top: 12px solid transparent;
  border-radius: 30px;
  top: 32px;
  left: -20px;
}
main.map .panel__tip.tip--had .tip svg {
  flex: 0 0 24px;
  margin-right: 10px;
}
main.map .panel__tip.tip--had .tip__text {
  display: flex;
  width: 100%;
}
main.map .panel__tip.tip--had .tip__close {
  margin-left: 10px;
}
main.map .panel__tip.tip--want {
  line-height: 132%;
  position: absolute;
  top: 155px;
  left: 352px;
  bottom: inherit;
}
main.map .panel__tip.tip--want .tip {
  background: linear-gradient(0deg, rgba(160, 161, 253, 0.15) 0%, rgba(160, 161, 253, 0.15) 100%), #FFF;
}
main.map .panel__tip.tip--want .tip:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-right: 12px solid transparent;
  border-bottom: 24px solid #EFF1FE;
  border-left: 12px solid transparent;
  border-radius: 30px;
  top: -20px;
  left: 80px;
  margin: 0 auto;
}
main.map .panel__tip.tip--want .tip svg {
  flex: 0 0 24px;
  margin-right: 10px;
}
main.map .panel__tip.tip--want .tip__text {
  display: flex;
  width: 100%;
}
main.map .panel__tip.tip--want .tip__close {
  margin-left: 10px;
}
main.map .panel__toggle {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
main.map .panel .btn-map {
  font-weight: 600;
  font-size: 13px;
  border-radius: 33px;
  padding: 5px 15px;
  background-color: var(--inter-color);
  color: var(--inter-second);
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
main.map .panel .btn-map svg path {
  fill: var(--inter-second);
}
main.map .panel .btn-map.active {
  color: var(--light-color);
  background-color: var(--inter-second);
}
main.map .panel .btn-map.active svg path {
  fill: var(--light-color);
}
main.map .panel .btn-map svg {
  margin-right: 10px;
}
main.map .panel__want {
  margin-right: 10px;
}
main.map .panel__save {
  margin-left: auto;
  box-shadow: 7px 6px 20px 2px rgba(0, 0, 0, 0.05);
  border-radius: 23px;
  background-color: #ffffff;
  color: #581DFF;
  font-size: 13px;
  border: none;
  cursor: pointer;
  padding: 6px 10px;
  display: none;
}
main.map .panel__save.active {
  display: block;
  color: #fff;
  background-color: #581DFF;
}
main.map .panel__form .form__want {
  display: none;
  flex-wrap: wrap;
  align-items: center;
}
main.map .panel__form .form__want.active {
  display: flex;
}
main.map .panel__form .form__want .want__text {
  flex: 0 0 190px;
  margin-bottom: 6px;
}
main.map .panel__form .form__want .want__text.second {
  flex: 1 0 270px;
}
main.map .panel__form .form__want .want__toggle {
  flex: 0 0 170px;
  margin-right: 16px;
}
main.map .panel__form .form__want .want__toggle .toggle__list {
  display: flex;
  align-items: center;
}
main.map .panel__form .form__want .want__toggle .toggle__list .list__item {
  flex: 0 0 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 16px;
  color: #6667AB;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--15, rgba(160, 161, 253, 0.15));
}
main.map .panel__form .form__want .want__toggle .toggle__list .list__item:not(:last-child) {
  margin-right: 5px;
}
main.map .panel__form .form__want .want__toggle .toggle__list .list__item.active {
  color: #fff;
  background-color: #6667ab;
}
main.map .panel__form .form__want .want__area {
  flex: 0 0 140px;
  border-radius: 10px;
  border: 1px solid rgba(160, 161, 253, 0.15);
  background: #FFF;
  font-size: 13px;
  font-weight: 500;
  color: #25282C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 16px;
  height: 34px;
  padding: 0 10px;
}
main.map .panel__form .form__want .want__area.active {
  outline: 1px solid #6667AB;
}
main.map .panel__form .form__want .want__area .area__value {
  flex: 0 0 60px;
  width: 60px;
  text-align: center;
  border: none;
  outline: none;
}
main.map .panel__form .form__want .want__area .area__value::-webkit-input-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__want .want__area .area__value:-moz-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__want .want__area .area__value::-moz-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__want .want__area .area__value:-ms-input-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__want .want__region {
  flex: 0 0 143px;
  display: flex;
  padding: 6px 10px;
  align-items: center;
  border-radius: 23px;
  background: rgba(160, 161, 253, 0.15);
  font-size: 13px;
  font-weight: 600;
  color: #581DFF;
  cursor: pointer;
}
main.map .panel__form .form__want .want__region svg {
  margin-right: 10px;
}
main.map .panel__form .form__want .want__region:hover {
  background-color: var(--active);
}
main.map .panel__form .form__want .want__region.active {
  color: var(--light-color);
  background-color: var(--inter-second);
}
main.map .panel__form .form__want .want__region.active svg path {
  fill: var(--light-color);
}
main.map .panel__form .form__had {
  align-items: center;
  flex-wrap: wrap;
  display: none;
  width: 479px;
}
main.map .panel__form .form__had.active {
  display: flex;
  gap: 0 10px;
}
main.map .panel__form .form__had.active .had__input {
  border-radius: 10px;
  border: 1px solid rgba(160, 161, 253, 0.15);
  background: #FFF;
  height: 38px;
  padding: 10px;
  font-size: 15px;
}
main.map .panel__form .form__had.active .had__input::-webkit-input-placeholder {
  font-size: 13px;
}
main.map .panel__form .form__had.active .had__input:-moz-placeholder {
  font-size: 13px;
}
main.map .panel__form .form__had.active .had__input::-moz-placeholder {
  font-size: 13px;
}
main.map .panel__form .form__had.active .had__input:-ms-input-placeholder {
  font-size: 13px;
}
main.map .panel__form .form__had.active .had__input:focus {
  outline: 1px solid #6667AB;
}
main.map .panel__form .form__had.active .input--stash, main.map .panel__form .form__had.active .input--monthly {
  flex: 0 0 224px;
  padding-left: 35px;
  margin-bottom: 10px;
  width: 224px;
  background: url(/wp-content/themes/vsedoma/front/app/img/money.svg) no-repeat 10px center/15px;
}
main.map .panel__form .form__had.active .input--monthly {
  background-image: url("/wp-content/themes/vsedoma/front/app/img/month.svg");
}
main.map .panel__form .form__had.active .had__text {
  flex: 0 0 100%;
  color: #343c45;
  margin-bottom: 6px;
}
main.map .panel__form .form__had.active .had__text.text--column {
  flex: 0 0 224px;
}
main.map .panel__form .form__had.active .had__text.text--small {
  flex: 0 0 40px;
}
main.map .panel__form .form__had.active .had__room {
  flex: 0 0 74px;
  border-radius: 10px;
  border: 1px solid rgba(160, 161, 253, 0.15);
  background: #FFF;
  padding-left: 5px;
  height: 38px;
  margin-bottom: 10px;
}
main.map .panel__form .form__had.active .had__area {
  flex: 0 0 224px;
  width: 94px;
  background: #FFF;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  border: 1px solid var(--30, rgba(147, 148, 226, 0.3));
  margin-bottom: 10px;
}
main.map .panel__form .form__had.active .had__area.active {
  border: 1px solid #6667AB;
}
main.map .panel__form .form__had.active .had__area .input--area {
  outline: none;
  width: 60px;
  border: none;
}
main.map .panel__form .form__had.active .had__area .input--area::-webkit-input-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__had.active .had__area .input--area:-moz-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__had.active .had__area .input--area::-moz-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__had.active .had__area .input--area:-ms-input-placeholder {
  color: rgba(99, 105, 112, 0.2);
}
main.map .panel__form .form__had.active .had__area .input--area:focus {
  outline: none;
}
main.map .panel__form .form__had.active .input--address {
  flex: 0 0 409px;
  border-radius: 10px;
  background: #FFF;
  padding: 5px 15px;
  margin-bottom: 6px;
}
main.map .panel__form .form__had .had__toggle {
  flex: 0 0 224px;
  margin-bottom: 10px;
}
main.map .panel__form .form__had .had__toggle .toggle__list {
  display: flex;
  align-items: center;
}
main.map .panel__form .form__had .had__toggle .toggle__list .list__item {
  flex: 0 0 40px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: 16px;
  color: #6667AB;
  border-radius: 10px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--15, rgba(160, 161, 253, 0.15));
}
main.map .panel__form .form__had .had__toggle .toggle__list .list__item:not(:last-child) {
  margin-right: 5px;
}
main.map .panel__form .form__had .had__toggle .toggle__list .list__item.active {
  color: #fff;
  background-color: #6667ab;
}
main.map .control {
  position: absolute;
  bottom: 0;
  right: 0;
  display: none;
}
main.map .info {
  position: absolute;
  height: 48px;
  bottom: 0;
  left: 16px;
  width: calc(100% - 32px);
  background: linear-gradient(0deg, rgba(147, 148, 226, 0.3) 0%, rgba(147, 148, 226, 0.3) 100%), #FFF;
  border-radius: 2px 2px 15px 15px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
  padding: 5px 20px;
}
main.map .info.active {
  background-color: #6667AB;
}
main.map .info.active .info__btn {
  background-color: #581DFF;
  color: #fff;
}
main.map .info.active .info__flat .flat__item:before {
  background-image: url(/wp-content/themes/vsedoma/front/app/img/stash-active.svg);
}
main.map .info.active .info__flat .flat__item.month:before {
  background-image: url(/wp-content/themes/vsedoma/front/app/img/month-active.svg);
}
main.map .info.active .info__flat .flat__item.price:before {
  background-image: url(/wp-content/themes/vsedoma/front/app/img/home-active.svg);
}
main.map .info.active .info__warn {
  color: #A2A3D0;
}
main.map .info .info__btn {
  opacity: 0;
  color: #581DFF;
  border-radius: 23px;
  background-color: #ffffff;
  padding: 6px 10px;
  font-size: 13px;
  font-weight: 600;
}
main.map .info__flat {
  flex: 0 0 344px;
  display: flex;
  align-items: center;
  color: #A2A3D0;
}
main.map .info__flat .flat__item {
  display: flex;
  align-items: center;
  position: relative;
  flex: 0 0 30%;
}
main.map .info__flat .flat__item:before {
  content: "";
  flex: 0 0 18px;
  height: 18px;
  margin-right: 8px;
  background: url(/wp-content/themes/vsedoma/front/app/img/money.svg) no-repeat center/contain;
}
main.map .info__flat .flat__item.month:before {
  background-image: url(/wp-content/themes/vsedoma/front/app/img/month.svg);
}
main.map .info__flat .flat__item.price:before {
  background-image: url(/wp-content/themes/vsedoma/front/app/img/home.svg);
}
main.map .info__op {
  margin-right: 370px;
  color: #fff;
  padding: 6px 9px;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 600;
  transform: translateY(0);
  flex: 0 0 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main.map .info__op.hide {
  display: none;
}
main.map .info__warn {
  display: flex;
  align-items: center;
  margin-right: 370px;
  color: #6667AB;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.13px;
  transform: translateY(0);
}
main.map .info__warn svg {
  margin-right: 10px;
}
main.map .info__warn.hide {
  display: none;
}
main.map .wrapper__info {
  position: absolute;
  right: 40px;
  width: 335px;
  height: 595px;
  bottom: -100vh;
  overflow: scroll;
  background-color: #fff;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.12);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  transition: 0.6s;
  -ms-overflow-style: none; /* IE и Edge */
  scrollbar-width: none; /* Firefox */
}
main.map .wrapper__info::-webkit-scrollbar {
  display: none;
}
main.map .wrapper__info.active {
  bottom: -506px;
}
main.map .wrapper__info.active--full {
  bottom: 0;
}
main.map .wrapper__info .offer__wrapper {
  display: none;
  position: relative;
}
main.map .wrapper__info .offer__wrapper.active {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 0 0;
  height: 100%;
}
main.map .wrapper__info .offer__wrapper .offer__tap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  cursor: pointer;
}
main.map .wrapper__info .offer__wrapper .offer__tap.hide {
  display: none;
}
main.map .wrapper__info .offer__wrapper .offer__controls {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
main.map .wrapper__info .offer__wrapper .offer__controls .offer__open {
  transition: 0.4s;
  height: 25px;
}
main.map .wrapper__info .offer__wrapper .offer__controls .offer__open.active {
  transform: rotate(180deg);
}
@media (max-width: 767px) {
  main.map .wrapper__info .offer__wrapper .offer__controls.hide {
    display: none;
  }
}
main.map .wrapper__info .offer__wrapper .offer__controls .controls__count {
  font-size: 18px;
  font-weight: 600;
  color: var(--background-block);
  display: flex;
  align-items: center;
}
main.map .wrapper__info .offer__wrapper .offer__controls .controls__count .count__text {
  position: relative;
  display: flex;
  flex: 0 0 190px;
}
main.map .wrapper__info .offer__wrapper .offer__controls .controls__count .count__text:before {
  content: "";
  flex: 0 0 18px;
  height: 18px;
  margin-top: 2px;
  margin-right: 10px;
  background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center/contain;
}
main.map .wrapper__info .offer__wrapper .offer__controls .controls__count .count__value {
  font-weight: 500;
  font-size: 16px;
  color: var(--accent);
  background-color: var(--background-color);
  padding: 2px 10px;
  border-radius: 30px;
  margin-left: 10px;
  width: fit-content;
}
main.map .wrapper__info .offer__wrapper .offer__controls .btn--small {
  display: none;
}
@media (max-width: 767px) {
  main.map .wrapper__info .offer__wrapper .offer__controls .btn--small {
    display: block;
  }
}
@media (max-width: 330px) {
  main.map .wrapper__info .offer__wrapper .offer__controls .btn--small {
    display: none;
  }
}
main.map .wrapper__info .offer__wrapper .offer__controls .controls__option {
  border: 0;
  background-color: transparent;
  padding: 0;
  display: none;
}
@media (max-width: 767px) {
  main.map .wrapper__info .offer__wrapper .offer__controls .controls__option {
    display: none;
  }
}
main.map .wrapper__info .offer__wrapper .new__plate {
  display: none;
  margin: 0 26px 0 16px;
  background-color: var(--active);
}
main.map .wrapper__info .offer__wrapper .new__plate .plate__row {
  align-items: center;
}
main.map .wrapper__info .offer__wrapper .new__plate .plate__row .plate__title:before {
  background: url("/wp-content/themes/vsedoma/front/app/img/objectcount.svg") no-repeat center/contain;
}
main.map .wrapper__info .offer__wrapper .new__plate .plate__row .row__btn {
  border-radius: 23px;
  background: rgba(160, 161, 253, 0.15);
  padding: 6px 10px;
  color: var(--background-block);
  font-size: 13px;
  font-weight: 600;
  border: none;
}
main.map .wrapper__info .offer__wrapper .new__plate .plate__row .row__btn.active {
  background-color: var(--inter-second);
  color: var(--light-color);
}
main.map .wrapper__info .offer__wrapper .new__plate .plate__form .form__address {
  border-radius: 10px;
}
@media (max-width: 767px) {
  main.map .wrapper__info .offer__wrapper .new__plate.active {
    display: block;
    flex: 0 0 auto;
  }
}
main.map .wrapper__info .offer__wrapper .offer__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
  padding: 0 15px;
  margin: 15px 0 0 0;
  list-style: none;
  width: 99%;
  overflow-y: scroll;
  height: 600px;
  align-content: start;
}
main.map .wrapper__info .offer__wrapper .offer__list::-webkit-scrollbar {
  background-color: transparent;
  width: 6px;
}
main.map .wrapper__info .offer__wrapper .offer__list::-webkit-scrollbar-track {
  display: none;
}
main.map .wrapper__info .offer__wrapper .offer__list::-webkit-scrollbar-thumb {
  border-radius: 30px;
  height: 2px;
  background: var(--old-543534, rgba(0, 0, 0, 0.05));
}
main.map .wrapper__info .offer__wrapper .offer__list.hide {
  display: none;
}
main.map .wrapper__info .offer__wrapper .offer__list .list__item {
  border-radius: 15px;
  height: 189px;
  background-color: var(--tel);
  position: relative;
  padding: 16px 16px 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
main.map .wrapper__info .offer__wrapper .offer__list .item__title {
  display: flex;
  background-color: var(--light-color);
  padding: 11px 20px;
  border-radius: 45px;
  font-size: 25px;
  font-weight: 600;
  width: fit-content;
  margin: 0;
}
main.map .wrapper__info .offer__wrapper .offer__list .item__title sup {
  margin-left: 2px;
  font-size: 22px;
}
main.map .wrapper__info .offer__wrapper .offer__list .item__title .item__area {
  margin-left: 10px;
  color: var(--accent);
}
main.map .wrapper__info .offer__wrapper .offer__list .item__addr, main.map .wrapper__info .offer__wrapper .offer__list .item__price {
  margin-top: 6px;
  color: var(--light-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.13px;
  margin-left: 20px;
  max-width: 180px;
  line-height: 125%;
}
main.map .wrapper__info .offer__wrapper .offer__list .item__btn {
  margin-top: auto;
  border-radius: 25px;
  padding: 8px 15px;
  background-color: #F1EDFF;
  color: var(--inter-second);
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  cursor: pointer;
  z-index: 3;
}
@media (max-width: 767px) {
  main.map .wrapper__info .offer__wrapper .offer__list .item__btn {
    padding: 5px 10px;
  }
}
main.map .wrapper__info .offer__wrapper .offer__list .item__img {
  position: absolute;
  bottom: 0;
  right: 0;
}
main.map .wrapper__info .offer__wrapper .offer__error {
  display: none;
  padding: 15px 40px;
}
@media (max-width: 767px) {
  main.map .wrapper__info .offer__wrapper .offer__error {
    padding: 15px 16px;
  }
}
main.map .wrapper__info .offer__wrapper .offer__error.active {
  display: block;
}
main.map .wrapper__info .offer__wrapper .offer__error .offer__tip {
  flex-wrap: wrap;
  background-color: #F5EEEF;
  color: var(--warn-color);
  width: 320px;
}
main.map .wrapper__info .offer__wrapper .offer__error .offer__tip .tip__title {
  margin: 0 0 10px 0;
  flex: 0 0 90%;
}
main.map .wrapper__info .offer__wrapper .offer__error .offer__tip .tip__warn {
  flex: 0 0 18px;
  height: 18px;
  background: url("/wp-content/themes/vsedoma/front/app/img/warn.svg") no-repeat center/contain;
}
main.map .wrapper__info .pass__wrapper {
  display: none;
  width: 100%;
  padding: 30px 15px;
}
main.map .wrapper__info .pass__wrapper.active {
  display: block;
}
main.map .wrapper__info .pass__wrapper .pass__control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
main.map .wrapper__info .pass__wrapper .pass__control .control__title {
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
main.map .wrapper__info .pass__wrapper .pass__control .control__back {
  font-weight: 500;
  padding: 6px 10px;
  display: flex;
  align-items: center;
}
main.map .wrapper__info .pass__wrapper .pass__control .control__back svg {
  margin-right: 8px;
}
main.map .wrapper__info .pass__wrapper .pass__my {
  margin-top: 10px;
  padding: 10px;
  background-color: var(--inter-color);
  border-radius: 15px;
}
main.map .wrapper__info .pass__wrapper .pass__my .my__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main.map .wrapper__info .pass__wrapper .pass__my .my__row:not(:first-child) {
  margin-top: 8px;
  color: var(--tel);
}
main.map .wrapper__info .pass__wrapper .pass__my .row__title {
  padding-bottom: 12px;
  position: relative;
  margin: 0;
}
main.map .wrapper__info .pass__wrapper .pass__my .row__title:before {
  content: "";
  width: 209px;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(102, 103, 171, 0.1);
}
main.map .wrapper__info .pass__wrapper .pass__my .row__btn {
  display: none;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  main.map .wrapper__info .pass__wrapper .pass__my .row__btn {
    display: block;
  }
}
main.map .wrapper__info .pass__wrapper .pass__flat {
  margin-top: 10px;
}
main.map .wrapper__info .pass__wrapper .pass__flat.hide {
  display: none;
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item {
  border-radius: 15px;
  height: 119px;
  background-color: var(--tel);
  position: relative;
  padding: 12px 18px 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item .item__title {
  margin: 7px 0 0 0;
  display: flex;
  background-color: var(--light-color);
  padding: 8px 20px 5px;
  border-radius: 45px;
  font-size: 25px;
  font-weight: 600;
  width: fit-content;
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item .item__title sup {
  margin-left: 2px;
  font-size: 18px;
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item .item__title .item__area {
  margin-left: 10px;
  color: var(--accent);
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item .item__price {
  margin-top: 8px;
  color: var(--light-color);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.13px;
  margin-left: 20px;
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item .item__choose {
  border-radius: 27px;
  padding: 2px 8px 3px 8px;
  color: var(--light-color);
  background-color: var(--inter-color);
  width: fit-content;
}
main.map .wrapper__info .pass__wrapper .pass__flat .flat__item .item__img {
  position: absolute;
  bottom: -15px;
  right: 0;
}
main.map .wrapper__info .pass__wrapper .pass__info {
  margin-top: 10px;
  border-radius: 15px;
  padding: 10px 0;
  background-color: var(--accent);
}
main.map .wrapper__info .pass__wrapper .pass__info.active {
  width: 100%;
  height: 810px;
  left: 0;
  top: 0;
  margin-top: 10px;
  padding: 0 0 30px 0;
  background-color: var(--light-color);
}
@media (max-width: 767px) {
  main.map .wrapper__info .pass__wrapper .pass__info.active {
    position: relative;
    margin-top: 10px;
    padding: 0;
  }
}
main.map .wrapper__info .pass__wrapper .pass__info.active .info__container {
  padding-top: 10px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: var(--accent);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__title {
  margin: 0;
  position: relative;
  color: var(--light-color);
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  flex: 0 0 170px;
}
@media (max-width: 330px) {
  main.map .wrapper__info .pass__wrapper .pass__info .info__title {
    flex: 0 0 155px;
  }
}
main.map .wrapper__info .pass__wrapper .pass__info .info__title:before {
  content: "";
  margin-right: 10px;
  flex: 0 0 18px;
  height: 18px;
  background: url("/wp-content/themes/vsedoma/front/app/img/Document.svg") no-repeat center/contain;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 108px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn:hover {
  background-color: #B3B3D5;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn.active {
  background-color: var(--light-color);
  color: var(--inter-second);
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn.active .btn__icon {
  background-color: var(--inter-color);
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn.active .btn__icon:before, main.map .wrapper__info .pass__wrapper .pass__info .info__btn.active .btn__icon:after {
  transform: rotate(45deg);
  top: 9px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn.active .btn__icon:after {
  transform: rotate(-45deg);
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn .btn__icon {
  display: block;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 16px;
  background-color: var(--inter-color);
  margin-left: 10px;
  margin-top: 2px;
  position: relative;
  transition: 0.3s;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn .btn__icon:after, main.map .wrapper__info .pass__wrapper .pass__info .info__btn .btn__icon:before {
  content: "";
  position: absolute;
  width: 9px;
  height: 2px;
  border-radius: 1px;
  background-color: var(--inter-second);
  transform: rotate(45deg);
  left: 3px;
  top: 10px;
  transition: 0.3s;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__btn .btn__icon:before {
  transform: rotate(-45deg);
  left: 9px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper {
  display: none;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper.active {
  flex: 0 0 100%;
  height: 557px;
  display: block;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: var(--light-color);
}
@media (max-width: 767px) {
  main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper.active {
    height: auto;
    padding-bottom: 10px;
  }
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__text {
  padding: 20px 10px 10px;
  background-color: var(--accent);
  color: var(--light-color);
  display: flex;
  flex-wrap: wrap;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 12px;
  position: relative;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__text:before {
  content: "";
  width: calc(100% - 20px);
  height: 1px;
  background-color: var(--light-color);
  position: absolute;
  opacity: 0.3;
  top: 10px;
  right: 10px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__text .info__column:nth-child(2) {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__text .info__column .info__price {
  font-size: 15px;
  font-weight: 600;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__title {
  color: var(--accent);
  margin: 12px 0 0 0;
  display: block;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__steps {
  display: block;
  width: 100%;
  margin: 0 auto;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item {
  display: flex;
  padding: 12px 0;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item:not(:last-child) {
  border-bottom: 1px solid rgba(147, 148, 226, 0.3);
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item .item__num {
  flex: 0 0 43px;
  padding: 4px 10px;
  background-color: var(--tel);
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  border-radius: 50%;
  text-align: center;
  height: fit-content;
  line-height: 140%;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item .item__num .num__val {
  font-size: 13px;
  font-weight: 600;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__steps .steps__item .item__text {
  margin-left: 8px;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color);
  flex: 0 0 calc(100% - 43px);
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__img {
  display: block;
  margin: 0 auto 0 auto;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__text--final {
  font-size: 15px;
  font-weight: 600;
  max-width: 343px;
  text-align: center;
  background-color: #FF8F51;
  padding: 8px 10px;
  border-radius: 10px;
  color: var(--light-color);
  margin: 0 auto;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__docs {
  margin: 12px auto 0 auto;
  border-radius: 15px;
  border: 1px solid rgba(160, 161, 253, 0.15);
  padding: 6px 10px;
  display: flex;
  font-size: 12px;
  max-width: 343px;
  color: var(--tel);
  font-weight: 400;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__docs .docs__check {
  background: url("/wp-content/themes/vsedoma/front/app/img/checkdocs.svg") no-repeat center/contain;
  flex: 0 0 30px;
  margin-right: 8px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__help {
  color: var(--text-color);
  margin: 12px auto 0 auto;
  max-width: 343px;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__send {
  margin: 12px auto 0 auto;
  max-width: 343px;
  display: flex;
  justify-content: space-between;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__send .send__link {
  padding: 0 10px;
  flex: 0 0 47%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: rgba(160, 161, 253, 0.15);
  color: var(--inter-second);
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}
main.map .wrapper__info .pass__wrapper .pass__info .info__wrapper .info__next .info__send .send__link svg {
  margin-right: 6px;
}
main.map .wrapper__info .pass__wrapper .pass__form {
  margin-top: 30px;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 20px;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper {
  margin-top: 10px;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab {
  font-size: 12px;
  padding: 9px 10px;
  font-family: var(--font-family);
  color: var(--tel);
  display: flex;
  align-items: center;
  justify-content: center;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab svg {
  margin-right: 3px;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab.active {
  color: var(--light-color);
  background-color: var(--tel);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__tabs .form__tab.active svg path {
  fill: var(--light-color);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__input {
  margin-top: 10px;
  width: 100%;
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid var(--old-543534, rgba(0, 0, 0, 0.05));
  font-family: Manrope;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__input:focus {
  outline: 1px solid var(--inter-second);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper ::-webkit-input-placeholder {
  color: rgba(37, 40, 44, 0.1);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper :-moz-placeholder {
  color: rgba(37, 40, 44, 0.1);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper ::-moz-placeholder {
  color: rgba(37, 40, 44, 0.1);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper :-ms-input-placeholder {
  color: rgba(37, 40, 44, 0.1);
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__sub {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__sub .sub__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  flex: 0 0 100%;
  order: 1;
  margin-top: 14px;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__sub .sub__text a {
  color: #4762AE;
  text-decoration: none;
}
main.map .wrapper__info .pass__wrapper .pass__form .form__wrapper .form__sub .form__submit {
  flex: 0 0 208px;
  padding: 16px;
  border-radius: 10px;
  background-color: var(--inter-second);
  color: var(--light-color);
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
  cursor: pointer;
  flex: 0 0 100%;
}
main.map .wrapper__info .finish__wrapper {
  padding-top: 32px;
  padding-bottom: 30px;
  display: none;
  padding-right: 16px;
  padding-left: 16px;
}
main.map .wrapper__info .finish__wrapper.active {
  display: block;
}
main.map .wrapper__info .finish__wrapper .finish__link {
  background: var(--15, rgba(160, 161, 253, 0.15));
  border-radius: 10px;
  text-align: center;
  width: 100%;
  color: #6567FE;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  text-decoration: none;
}
main.map .wrapper__info .finish__wrapper .finish__info {
  border-radius: 20px;
  background-color: var(--accent);
  background-image: url(/wp-content/themes/vsedoma/front/app/img/lead.svg);
  background-position: 100% 151%;
  background-repeat: no-repeat;
  padding: 16px 15px;
  height: 252px;
}
main.map .wrapper__info .finish__wrapper .finish__info .finish__title {
  font-size: 18px;
  font-weight: 600;
  color: var(--light-color);
  margin: 0 0 10px 0;
}
main.map .wrapper__info .finish__wrapper .finish__info .finish__text {
  font-size: 12px;
  font-weight: 400;
  color: var(--light-color);
  max-width: 206px;
}
main.map .wrapper__info .finish__wrapper .finish__pass {
  margin-top: 20px;
  background-color: rgba(160, 161, 253, 0.15);
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  text-align: center;
  color: var(--active);
  text-decoration: none;
  padding: 16px;
  border-radius: 10px;
  display: block;
}
main.map .wrapper__info .finish__wrapper .finish__info--pass {
  display: block;
}
main.map .ymaps-2-1-79-balloon {
  border-radius: 10px;
  overflow: hidden;
}
main.map .ymaps-2-1-79-balloon ymaps {
  line-height: 130%;
  font-size: 12px;
  color: var(--inter-second);
}

ymaps.active .ymaps-2-1-79-map ymaps, ymaps.active .ymaps-2-1-79-map ymaps:after, ymaps.active .ymaps-2-1-79-map ymaps:before {
  cursor: pointer !important;
}