// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: 'Manrope', sans-serif;
  --content-width: 1400px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --text-color: #25282C;
  --background-color: #F6F7F8;
  --background-block: #343C45;
  --light-color: #fff;
  --inter-second: #581DFF;
  --inter-color: rgba(160, 161, 253, 0.15);
  --korp-color: #B0CB1F;
  --not-active: #A0A1FD;
  --active: rgba(147, 148, 226, 0.30);
  --accent: #FF8F51;
  --tel: #6667AB;
  --text: #DCDCDC;
  --warn-color: #EB5757;
}
